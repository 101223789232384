import React, { useContext, useEffect } from 'react';
import { Dispatch, SetStateAction } from 'react';
import { useEvents, Props as UseEventsProps } from '@hooks/requests/useEvents';
import { EventTabType } from '@utils/types/event';
import { EventsInfoModal } from './EventsInfoModal';
import { EventsTable } from './EventsTable';
import { filteredEventsContext } from './filtered-events.context';

type Props = {
  isPortfolio?: boolean;
  tabType: EventTabType;
  eventableId: UseEventsProps['eventableId'];
  eventableType: UseEventsProps['eventableType'];
  eventTypes: UseEventsProps['eventTypes'];
  infoModalIsOpen: boolean;
  setInfoModalIsOpen: Dispatch<SetStateAction<boolean>>;
  light?: boolean;
};

export const EventsPageWrapper = ({
  tabType,
  isPortfolio,
  eventableId,
  eventableType,
  eventTypes,
  infoModalIsOpen,
  setInfoModalIsOpen,
  light,
}: Props) => {
  const { filteredEvents, startDate, endDate, setAllEvents } = useContext(filteredEventsContext);
  const { data: allEvents, isLoading } = useEvents({
    start: startDate,
    stop: endDate,
    eventableId,
    eventableType,
    eventTypes,
  });

  useEffect(() => {
    setAllEvents(allEvents || []);
  }, [setAllEvents, allEvents]);

  return (
    <>
      {isPortfolio && <EventsInfoModal isOpen={infoModalIsOpen} setIsOpen={setInfoModalIsOpen} />}
      <EventsTable
        tabType={tabType}
        data={filteredEvents}
        isLoading={isLoading}
        isPortfolio={isPortfolio}
        light={light}
      />
    </>
  );
};
