import {
  getDispatchProgramSharingRights,
  updateDispatchProgramSharingRight,
  type DispatchProgramSharingRightBody,
} from '@api/dispatchProgramSharingRights';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { DispatchProgramSharingRight } from '@utils/types/unavailability';
import type { ToggleSharingRightsModal } from '../SharingRights/SharingRightTable';

const QUERY_KEY = ['dispatchProgramSharingRights'];

export const useDispatchProgramSharingRights = () => {
  return useQuery({
    queryKey: QUERY_KEY,
    queryFn: getDispatchProgramSharingRights,
  });
};

export const useUpdateDispatchProgramSharingRights = (toggle: ToggleSharingRightsModal) => {
  const queryClient = useQueryClient();
  const updateMutation = useMutation({
    mutationKey: ['updateDispatchProgramSharingRight'],
    mutationFn: ({ body, id }: { body: DispatchProgramSharingRightBody; id: number }) =>
      updateDispatchProgramSharingRight(body, id),
    onSuccess: (newSharingRight) => {
      queryClient.setQueryData<DispatchProgramSharingRight[]>(QUERY_KEY, (prevSharingRights) => {
        return prevSharingRights?.map((prevSharingRight) => {
          if (prevSharingRight.id === newSharingRight.id) return newSharingRight;

          return prevSharingRight;
        });
      });

      toggle(null);
    },
  });

  return updateMutation;
};
