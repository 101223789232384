import React, { useMemo, useState } from 'react';
import { Locale } from '@utils/types/common-types';
import { User } from '@utils/types/user';
import { backendDataSerializer } from './backend-data.serializer';
import { FilterProvider } from './Filters/filters.context';
import { useFilteredPortfolio } from './Filters/useFilters';
import { usePortfolioDefaultParams } from './hooks/usePortfolioDefaultParams';
import { PortFolioContextType, portfolioContext } from './portfolio.context';
import { DataType, PortFolioDisplayType } from './portfolio.definitions';
import { usePortfolioQuery } from './usePortfolio';

export const PortfolioProvider = ({
  locale,
  user,
  children,
}: React.PropsWithChildren<{ locale: Locale; user: User }>) => {
  const [errorBanner, setErrorBanner] = useState<string | undefined>();
  const { firstDate, lastDate, defaultTab, showMap, onlyConsumers } = usePortfolioDefaultParams(
    locale,
    user,
    errorBanner,
    setErrorBanner,
  );

  const [startDate, setStartDate] = useState<string | undefined>(firstDate.format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState<string | undefined>(lastDate.format('YYYY-MM-DD'));
  const [dataType, setDataType] = useState<DataType>(defaultTab);
  const [displayMode, setDisplayMode] = useState<PortFolioDisplayType>(
    showMap && dataType !== 'books' ? 'map' : 'table',
  );

  const { data: meterData, isPending: loading, error } = usePortfolioQuery(startDate, endDate);
  const tabData = useMemo(
    () => meterData && defaultTab && backendDataSerializer(defaultTab === 'books' ? meterData?.books : meterData?.data),
    [defaultTab, meterData],
  );

  const { filteredPortfolio, filterContext } = useFilteredPortfolio(tabData, dataType);

  const context = useMemo<PortFolioContextType>(
    () => ({
      data: [
        ...(meterData?.data ? backendDataSerializer(meterData?.data) : []),
        ...(meterData?.books ? backendDataSerializer(meterData?.books) : []),
      ],
      onlyConsumers,
      dataType,
      loading,
      error: error?.message || undefined,
      errorBanner,
      sum_realtime_portfolio: meterData?.sum_realtime_portfolio,
      realtime_ratio: meterData?.realtime_ratio,
      setDataType,
      displayMode,
      setDisplayMode,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      portfolio: filteredPortfolio,
    }),
    [
      dataType,
      displayMode,
      endDate,
      error?.message,
      errorBanner,
      filteredPortfolio,
      loading,
      meterData?.books,
      meterData?.data,
      meterData?.realtime_ratio,
      meterData?.sum_realtime_portfolio,
      onlyConsumers,
      startDate,
    ],
  );

  return (
    <FilterProvider value={filterContext}>
      <portfolioContext.Provider value={context}>{children}</portfolioContext.Provider>
    </FilterProvider>
  );
};
