import React from 'react';
import { Row, Col } from '@GDM/layout';
import { FormSectionLayout, type ContractFormSectionProps } from '../../components/FormSection/FormSectionLayout';
import { NumericInput } from '../../components/Inputs/Numeric';
import { TextInput } from '../../components/Inputs/Text';

export const LandLeasingParcel = ({ title, readOnly, form: { control } }: ContractFormSectionProps) => {
  return (
    <FormSectionLayout
      title={title}
      body={
        <Row className="p-3">
          <Col>
            <TextInput
              control={control}
              name="contract_sub_periods_attributes.0.extra_params.land_leasing_parcel_name"
              label="sales_management.land_leasing.parcel_name"
              readOnly={readOnly}
              rules={{ required: true }}
            />
          </Col>
          <Col>
            <NumericInput
              control={control}
              name="contract_sub_periods_attributes.0.extra_params.land_leasing_nb_of_turbines_on_parcel"
              label="sales_management.land_leasing.nb_of_turbines_on_parcel"
              readOnly={readOnly}
              rules={{ required: true }}
            />
          </Col>
        </Row>
      }
    />
  );
};
