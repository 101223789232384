import React, { useState } from 'react';
import { useInvoicesByType } from '@hooks/requests/meter-invoices/useInvoicesByType';
import dayjs from 'dayjs';
import InvoicePageWrapper from '../InvoicePageWrapper';

const YearlyRegulInvoices = () => {
  const [{ startDate, endDate }, setDates] = useState<{
    startDate: Date;
    endDate: Date | null;
  }>({
    startDate: dayjs().startOf('year').add(-1, 'year').toDate(),
    endDate: dayjs().startOf('year').toDate(),
  });
  const req = useInvoicesByType('yearly_regul', 'all', startDate, endDate);

  const handleDateChange = (startDate: Date | null) => {
    setDates({
      startDate: dayjs(startDate).startOf('year').toDate(),
      endDate: dayjs(startDate).startOf('year').add(1, 'year').toDate(),
    });
  };

  return (
    <InvoicePageWrapper
      invoices={req.data ?? []}
      invoiceType="yearly_regul"
      isLoading={req.isPending}
      startDate={startDate}
      onDateChange={handleDateChange}
      isDateRange={false}
      yearlyDate
      direction="all"
      showDetailsButton={false}
    />
  );
};

export default YearlyRegulInvoices;
