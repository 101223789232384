import React, { useState, useMemo } from 'react';
import { Toggle } from '@GDM/Toggle';
import { useServiceStatus } from '@hooks/requests/useServiceStatus';
import Page from '@pages/Page';
import { ServiceStatusTable } from '@pages/ServiceStatus/Table/ServiceStatusTable';
import { User } from '@utils/types/user';

export const ServiceStatus = ({ user }: { user: User }) => {
  const { data: service_status, isLoading, error } = useServiceStatus();

  const [showDownOnly, setShowDownOnly] = useState(true);

  const filteredServices = useMemo(() => {
    if (!service_status) return [];
    if (showDownOnly) {
      return service_status.filter((service) => !service.up);
    }

    return service_status;
  }, [service_status, showDownOnly]);

  return (
    <Page
      title="sidebar.admin_settings.service_status"
      error={error?.message}
      isLoading={isLoading}
      user={user}
      layout="no-background"
      fixedHeightContainer
      useQueryClient={false}
    >
      <div>
        {!isLoading && service_status && (
          <>
            <label htmlFor="downOnlyToggle">
              <Toggle
                id="downOnlyToggle"
                value={showDownOnly}
                label="service_status.filter_down"
                onChange={(checked) => setShowDownOnly(checked)}
              />
            </label>

            <ServiceStatusTable service_status={filteredServices} />
          </>
        )}
      </div>
    </Page>
  );
};

export default ServiceStatus;
