import React from 'react';
import SmartTranslator from '@components/SmartTranslator';
import { Currency } from '@utils/types/currency';
import ProductionChart from '../charts/ProductionChart';
import MarketData from '../MarketData';
import MonthlyResults from '../MonthlyResults';
import YearlyResults from '../YearlyResults';

const ReportPageCharts = ({ currency }: { currency: Currency }) => {
  return (
    <main>
      <div className="reports">
        <div className="report">
          <h5>
            <SmartTranslator translationKey="sales_management.simple_monthly_results" />
          </h5>
          <MonthlyResults printable />
        </div>

        <div className="report">
          <h5>
            <SmartTranslator translationKey="sales_management.simple_annual_results" />
          </h5>
          <YearlyResults printable />
        </div>
      </div>

      <hr />

      <div className="market">
        <h5>
          <SmartTranslator translationKey="sales_management.market_price" />
        </h5>

        <MarketData printable />
      </div>

      <hr />

      <div className="market">
        <h5>
          <SmartTranslator translationKey="sales_management.prod_vs_revenues" />
        </h5>
        <ProductionChart height={250} disablePadding printable currency={currency} />
      </div>
    </main>
  );
};

export default ReportPageCharts;
