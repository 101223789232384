import React from 'react';
import { Badge } from '@GDM/Badge';
import { Tooltip } from '@GDM/Tooltip/Tooltip';
import useTranslation from '@hooks/useTranslation';
import { Contract } from '@utils/types/contract';
import { ContractNumberCell, ParcelNameCell } from '../ContractNumberCell';
import { TypeBadge } from './TypeBadge';

export const TypeCell = ({ contract }: { contract: Partial<Contract> }) => {
  const { t } = useTranslation();

  const tooltip = contract.card_i ? `${t('sales_management.card_i')} : ${contract.card_i}` : undefined;
  const showContractNb = contract.type !== 'ContractLandLeasing';

  const showParcelName = contract.type === 'ContractLandLeasing';
  const parcelName = contract.contract_sub_periods?.[0]?.extra_params?.land_leasing_parcel_name;

  return (
    <Tooltip tooltip={tooltip}>
      <div>
        <TypeBadge contract={contract} />

        <div className="mt-1 d-flex align-items-center hide-empty gap-2">
          {showContractNb && contract.contract_nb && <ContractNumberCell contractNumber={contract.contract_nb} />}
          {showParcelName && parcelName && <ParcelNameCell parcelName={parcelName} />}

          <Badge
            variant={contract.direction === 'sell' ? 'primary-1' : 'primary-2'}
            label={`sales_management.contract_${contract.direction}`}
          />
        </div>
      </div>
    </Tooltip>
  );
};
