import React, { useState } from 'react';
import { Tabs, type Tab } from '@GDM/Tabs';
import type { LayoutProps } from '@pages/Page/Page';
import Page from '@pages/Page/Page';
import { DispatchProgram } from './DispatchProgram/DispatchProgram';
import { SharingRights } from './SharingRights/SharingRights';

const Unavailabilities = ({ user }: LayoutProps) => {
  const hash = window.location.hash?.slice(1);
  const selectedTabFromHash = hashIsValid(hash) ? hash : null;
  const [selectedTab, setSelectedTab] = useState<UnavailabilitiesTab>(selectedTabFromHash || 'sharing_rights');
  const tabs: Tab<UnavailabilitiesTab>[] = [
    { id: 'sharing_rights', name: 'monitoring.unavailabilities.aggregator_sharing' },
    { id: 'dispatch_program', name: 'monitoring.unavailabilities.dispatch_program' },
  ];

  const tabsContent: Record<UnavailabilitiesTab, JSX.Element> = {
    sharing_rights: <SharingRights />,
    dispatch_program: <DispatchProgram />,
  };

  return (
    <Page user={user} title="sidebar.unavailabilities">
      <Tabs tabs={tabs} onTabChange={setSelectedTab}>
        {tabsContent[selectedTab]}
      </Tabs>
    </Page>
  );
};

type UnavailabilitiesTab = 'sharing_rights' | 'dispatch_program';

const hashIsValid = (hash: string): hash is UnavailabilitiesTab => {
  return ['sharing_rights', 'dispatch_program'].includes(hash);
};

export default Unavailabilities;
