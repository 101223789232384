import React from 'react';
import { CellContext } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { FieldValues } from 'react-hook-form';

export function TimeCell<T extends FieldValues>({ getValue }: React.PropsWithChildren<CellContext<T, unknown>>) {
  const value = getValue();
  if (!value || (typeof value !== 'string' && typeof value !== 'number')) return '--';

  const time = dayjs(value).format('YYYY-MM-DD HH:mm');

  return <>{time}</>;
}
