import React from 'react';
import useTranslation from '@hooks/useTranslation';
import { CellContext } from '@tanstack/react-table';
import { MeterInvoice } from '@utils/types/meter-invoice';

export const LandLeasingQuantityCell = ({ row: { original } }: CellContext<MeterInvoice, unknown>) => {
  const { t } = useTranslation();
  const nbOfTurbines = original.contract_sub_period?.extra_params?.land_leasing_nb_of_turbines_on_parcel;

  if (!nbOfTurbines) return <></>;

  return (
    <div>
      <b>{nbOfTurbines} </b>
      <span style={{ fontWeight: 'lighter' }}>{t('common.turbines')}</span>
    </div>
  );
};
