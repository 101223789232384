import React from 'react';
import { NumberCell } from '@GDM/Table/Cells/NumberCell';
import { CellContext } from '@tanstack/react-table';
import { Unavailability } from '@utils/types/unavailability';

export function PmaxCell<T extends Unavailability>(props: React.PropsWithChildren<CellContext<T, unknown>>) {
  return (
    <NumberCell
      value={props.row.original.p_max}
      numberOptions={{ maximumFractionDigits: 2, minimumFractionDigits: 2 }}
    />
  );
}
