import { Contract } from './contract';
import { Installation } from './installation';

export type EventType =
  | 'AnnualProductionExceedsCap'
  | 'AnnualProductionExceedsDafr'
  | 'CommunicationFailure'
  | 'DailyProductionOnNegativePrice'
  | 'HighUnderPerformance'
  | 'HighDiffPublishedProduction'
  | 'InconsistentProduction'
  | 'InvoiceMissingData'
  | 'OutOfRangeReactive'
  | 'ProductionAnomaly'
  | 'ProductionOnNegativePrice'
  | 'ProductionStop'
  | 'RenewalReminder'
  | 'UnderPerformance';

export const ALERT_EVENT_TYPES: EventType[] = [
  'AnnualProductionExceedsCap',
  'AnnualProductionExceedsDafr',
  'CommunicationFailure',
  'DailyProductionOnNegativePrice',
  'HighUnderPerformance',
  'HighDiffPublishedProduction',
  'InconsistentProduction',
  'InvoiceMissingData',
  'OutOfRangeReactive',
  'ProductionAnomaly',
  'ProductionOnNegativePrice',
  'ProductionStop',
  'UnderPerformance',
];

export const REMINDER_EVENT_TYPES: EventType[] = ['RenewalReminder'];

export type EventTabType = 'alerts' | 'reminders';

export type Event = {
  id: number;
  type: EventType;
  contract?: {
    start_date: string;
    end_date: string;
    direction: Contract['direction'];
    type: Contract['type'];
    buyer_name: string;
    seller_name: string;
  };
  eventable_type: 'Installation' | 'MeterInvoicing';
  eventable_id: string;
  started_at: string;
  ended_at: string;
  start_date: string;
  end_date: string;
  installation: Pick<Installation, 'name' | 'energy' | 'country'>;
  finished: boolean;
};

export type RenewalReminderConfiguration = {
  id: number;
  days_before_contract_end: number;
};
