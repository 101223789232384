import React, { useCallback } from 'react';
import ControlledRadioButtons from '@components/FormInputs/ControlledRadioButtons';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { FilterContainer, Filters, useDynamicOptions } from '@GDM/Filters';
import useBooks from '@hooks/requests/useBooks';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import type { EnergyType, Option } from '@utils/types/common-types';
import type { DispatchProgramSharingStatus } from '@utils/types/unavailability';
import intersection from 'lodash/intersection';
import type { UseFormReturn } from 'react-hook-form';
import type { DispatchProgramFilters as TDispatchProgramFilters } from './DispatchProgram';

export const DispatchProgramFilters = ({
  filtersForm,
  filteredDispatchProgram,
  dispatchProgram,
}: {
  filtersForm: UseFormReturn<TDispatchProgramFilters>;
  filteredDispatchProgram: DispatchProgramSharingStatus[];
  dispatchProgram: DispatchProgramSharingStatus[];
}) => {
  const { control, reset } = filtersForm;

  const { data } = useBooks();

  const getBookOptions = useCallback(
    (filteredDispatchProgram: DispatchProgramSharingStatus[]) => {
      const books = data || [];
      const allInstallationNames = filteredDispatchProgram
        ? filteredDispatchProgram.map((unavailability) => unavailability.installation.name)
        : [];

      const relevantBooks = books
        ? books.filter((book) => intersection(book.installation_names, allInstallationNames).length > 0)
        : [];

      return relevantBooks
        .map((book) => ({ label: book.name, value: book.name, energy: 'book' }))
        .sort(sortOptionsByLabelAsc);
    },
    [data],
  );

  const bookOptions = useDynamicOptions(getBookOptions, 'bookUuid', filteredDispatchProgram, dispatchProgram);

  const installationOptions = useDynamicOptions(
    getInstallationOptions,
    'installationName',
    filteredDispatchProgram,
    dispatchProgram,
  );

  return (
    <Filters form={filtersForm} className="p-3" onClear={() => reset()}>
      <FilterContainer>
        <ControlledSelect
          control={control}
          name="installationName"
          placeholder="common.filters.installation_name"
          options={installationOptions}
          isMulti
          inline
          full
          isInstallationOrBook
        />
      </FilterContainer>

      <FilterContainer>
        <ControlledSelect
          control={control}
          name="bookUuid"
          options={bookOptions}
          placeholder="admin.books.book_name"
          isClearable
          isInstallationOrBook
          isMulti
          inline
        />
      </FilterContainer>

      <FilterContainer size="fit">
        <ControlledRadioButtons
          control={control}
          name="energy"
          options={[
            { label: 'common.all', value: 'all' },
            { label: 'common.wind.eolian', value: 'wind' },
            { label: 'common.pv.solar', value: 'solar' },
            { label: 'common.hydro.short', value: 'hydro' },
          ]}
        />
      </FilterContainer>

      <FilterContainer>
        <ControlledSelect
          control={control}
          name="status"
          options={[
            { label: 'monitoring.unavailabilities.dispatch_statuses.success_label', value: true },
            { label: 'monitoring.unavailabilities.dispatch_statuses.failure_label', value: false },
          ]}
          placeholder="monitoring.unavailabilities.sharing_status"
          isClearable
          isMulti
          inline
        />
      </FilterContainer>
    </Filters>
  );
};

const getInstallationOptions = (dispatchPrograms: DispatchProgramSharingStatus[]) => {
  if (!dispatchPrograms.length) {
    return [];
  }

  const installations = new Map<string, Option<string> & { energy: EnergyType }>();

  dispatchPrograms.forEach((unavailability) => {
    installations.set(unavailability.installation.name, {
      label: unavailability.installation.name,
      value: unavailability.installation.name,
      energy: unavailability.installation.energy,
    });
  });

  return Array.from(installations.values()).sort(sortOptionsByLabelAsc);
};
