export const getRenewalReminderConfigurationLabel = (days_before_contract_end: number, light: boolean) => {
  let label = null;
  switch (days_before_contract_end) {
    case 14:
      label = 'admin.alerts.rules.2_weeks';
      break;
    case 30:
      label = 'admin.alerts.rules.1_month';
      break;
    case 3 * 30:
      label = 'admin.alerts.rules.3_months';
      break;
    case 4 * 30:
      label = 'admin.alerts.rules.4_months';
      break;
    case 6 * 30:
      label = 'admin.alerts.rules.6_months';
      break;
    default:
      label = 'admin.alerts.rules.some_days';
      break;
  }

  if (!light) label = `${label}_before_contract_end_date`;

  return label;
};
