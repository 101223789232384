import type { RequestError, RequestErrorBody } from '@hooks/useRequest';
import fetchWrapper from '@utils/fetch';
import { finish_v2_event_path } from '@utils/routes';
import type { Event } from '@utils/types/event';

export const finishEvent = async ({ event_id }: { event_id: number }) => {
  const response = await fetchWrapper(finish_v2_event_path(event_id), {
    method: 'PATCH',
  });

  let json: Event | RequestErrorBody;

  try {
    json = await response.json();
  } catch (error) {
    throw new Error('errors.unknown_error');
  }

  if (!response.ok) {
    const code = 'code' in json && json.code ? json.code : 'errors.unknown_error';
    const message = 'message' in json && json.message ? json.message : 'errors.unknown_error';
    const errors = 'errors' in json && json.errors ? (json.errors as RequestError[]) : [];

    throw { code, message, errors } satisfies RequestErrorBody;
  }

  return json as Event;
};
