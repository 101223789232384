import type { Row } from '@tanstack/react-table';
import type Book from '@utils/types/book';
import type { EnergyType } from '@utils/types/common-types';

/**
 * The difference between using built-in filterFn: 'arrIncludes' and this method, is that when our array is empty, the built-in
 * filterFn will always return false, while we want it to return true
 * `multi` means that the values will be an array
 */
export const arrayFilterMulti = <T>(row: Row<T>, id: string, filterValues: string[]) => {
  return filterValues.length > 0 ? filterValues.includes(row.getValue<string>(id)) : true;
};

export const arrayFilter = <T>(row: Row<T>, id: string, filterValue: string) => {
  return filterValue ? row.getValue<string>(id) === filterValue : true;
};

/**
 * Specific case for energy as the default value is 'all'
 */
export const toggleFilterWithAll = <T>(row: Row<T>, id: string, filterValue: 'all' | EnergyType) => {
  return filterValue === 'all' || (filterValue ? row.getValue<string>(id) === filterValue : true);
};

/**
 * Specific case for books as it is a many-to-many relationship
 */
export const arrayFilterMultiBook = <T>(row: Row<T>, id: string, filterValues: string[]) => {
  const books = row.getValue<Book[]>(id);

  if (filterValues.length === 0) {
    return true;
  }

  if (books.length === 0) {
    return false;
  }

  return books.some((book) => filterValues.includes(book.name));
};

export const arrayFilterBook = <T>(row: Row<T>, id: string, filterValue: string) => {
  const books = row.getValue<Book[]>(id);

  return books.some((book) => book.name === filterValue);
};
