import React, { useMemo, useState } from 'react';
import type { DispatchProgramSharingRightBody } from '@api/dispatchProgramSharingRights';
import { RadioButtons } from '@GDM/forms';
import { Modal } from '@GDM/Modal';
import { Table, TableActions, TableBody, TableHead, TablePageSizeSelect, TablePagination } from '@GDM/Table';
import { Text } from '@GDM/Text';
import useTranslation from '@hooks/useTranslation';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  type TableState,
} from '@tanstack/react-table';
import type { BaseSharingRight, DispatchProgramSharingRight, SharingRight } from '@utils/types/unavailability';
import omit from 'lodash/omit';
import { Controller, FormProvider, useForm, type UseFormReturn } from 'react-hook-form';
import { SharingRightActions } from '../shared/SharingRightActions';
import type { ToggleSharingRightsModal } from '../SharingRights/SharingRightTable';
import type { DispatchProgramSharingRightsFiltersForm } from './DispatchProgram';
import { DispatchProgramSharingRightFilters } from './DispatchProgramSharingRightFilters';
import { useColumns } from './useColumns';
import {
  useDispatchProgramSharingRights,
  useUpdateDispatchProgramSharingRights,
} from './useDispatchProgramSharingRights';

/**
 * We pass both filters and filtersForm as parameters, because form.watch is not memoized and can cause infinite re-renders
 * when declaring it as the same level as other states
 */
export const DispatchProgramSharingRightTable = ({
  filters,
  filtersForm,
}: {
  filters: DispatchProgramSharingRightsFiltersForm;
  filtersForm: UseFormReturn<DispatchProgramSharingRightsFiltersForm>;
}) => {
  const { t } = useTranslation();
  const [selectedSharingRightId, setSelectedSharingRightId] = useState<SharingRight['id'] | null>(null);

  const { data, isPending } = useDispatchProgramSharingRights();
  const sharingRights = useMemo(() => data || [], [data]);

  const selectedSharingRight = useMemo(() => {
    return sharingRights.find((unavailabilitySharingRight) => unavailabilitySharingRight.id === selectedSharingRightId);
  }, [selectedSharingRightId, sharingRights]);

  const columns = useColumns(setSelectedSharingRightId);

  const columnFilters = useMemo(() => {
    return Object.entries(omit(filters, 'last_selected_filter')).map(([id, value]) => ({ id, value }));
  }, [filters]);

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 25 });

  const tableState = useMemo<Partial<TableState>>(() => {
    return {
      pagination,
      columnFilters,
      // We hide them, but we still need them for filtering purposes
      columnVisibility: { country: false, book: false },
    };
  }, [pagination, columnFilters]);

  const table = useReactTable({
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    data: sharingRights,
    columns,
    state: tableState,
    initialState: { sorting: [{ id: 'installation', desc: false }] },
    onPaginationChange: setPagination,
  });

  const filteredSharingRights = table.getFilteredRowModel().rows.map((row) => row.original);

  return (
    <>
      <div className="p-3">
        <FormProvider {...filtersForm}>
          <DispatchProgramSharingRightFilters
            form={filtersForm}
            allSharingRights={sharingRights}
            filteredSharingsRights={filteredSharingRights}
          />
        </FormProvider>

        <SharingRightActions
          fileNamePrefix="Streem_Unavailability_Dispatch_Program_Sharing_Rights"
          csvData={filteredSharingRights}
          csvHeader={[
            { label: 'common.installation', key: 'installation.name' },
            { label: 'common.country', key: 'installation.country' },
            {
              label: 'monitoring.unavailabilities.sharing_permission',
              key: 'formatted_sharing_status',
              transform: (value, row) => {
                return t(`monitoring.unavailabilities.${row.formatted_sharing_status}`);
              },
            },
            { label: 'monitoring.unavailabilities.credentials', key: 'credential.username' },
          ]}
        />
      </div>

      <SharingRightForm selectedSharingRight={selectedSharingRight} toggle={setSelectedSharingRightId} />

      <Table hasActions>
        <TableHead table={table} />
        <TableBody table={table} isLoading={isPending} />
      </Table>

      <TableActions>
        <TablePageSizeSelect
          totalEntries={sharingRights.length}
          pageSize={table.getState().pagination.pageSize}
          setPageSize={table.setPageSize}
        />
        <TablePagination pageCount={table.getPageCount()} gotoPage={table.setPageIndex} />
      </TableActions>
    </>
  );
};

const SharingRightForm = ({
  selectedSharingRight,
  toggle,
}: {
  selectedSharingRight: DispatchProgramSharingRight | undefined;
  toggle: ToggleSharingRightsModal;
}) => {
  const { t } = useTranslation();
  const form = useForm<DispacthSharingRightForm>({
    values: {
      id: selectedSharingRight?.id || -1,
      sharing_status: selectedSharingRight?.sharing_status || 'unshared',
    },
  });

  const { mutate: update, isPending, error } = useUpdateDispatchProgramSharingRights(toggle);

  const isOpen = selectedSharingRight !== undefined;

  const handleSubmit = (data: DispacthSharingRightForm) => {
    const { id, ...body } = data;

    update({ body, id });
  };

  return (
    <Modal
      header={t('monitoring.unavailabilities.dispatch_sharing_rights_modal_title', {
        name: selectedSharingRight?.installation?.name || '',
      })}
      isOpen={isOpen}
      toggle={() => toggle(null)}
      submitAction={form.handleSubmit(handleSubmit)}
      isLoading={isPending}
      footer={error && <Text multiline text={error?.message} type="danger" />}
    >
      <div className="d-flex flex-column gap-3">
        <Controller
          control={form.control}
          name="sharing_status"
          rules={{ required: true }}
          render={({ field }) => (
            <RadioButtons
              name={field.name}
              label="monitoring.unavailabilities.sharing_permission"
              onChange={field.onChange}
              selected={field.value}
              options={[
                { label: 'monitoring.unavailabilities.shared', value: 'shared' },
                { label: 'monitoring.unavailabilities.unshared', value: 'unshared' },
              ]}
              disabled={!selectedSharingRight?.installation?.show_forecast || !selectedSharingRight?.credential}
              size="lg"
            />
          )}
        />
      </div>
    </Modal>
  );
};

type DispacthSharingRightForm = DispatchProgramSharingRightBody & {
  id: BaseSharingRight['id'];
};
