import React from 'react';
import { Badge } from '@GDM/Badge';
import { Icon } from '@GDM/Icon';
import { CellContext } from '@tanstack/react-table';
import getContractTypeLabel from '@utils/contract/getLabel';
import { Event } from '@utils/types/event';

export const ContractCell = ({ row: { original } }: CellContext<Event, unknown>) => {
  if (!original.contract) return <></>;

  const contract = original.contract;
  const counterpartName = contract.direction === 'sell' ? contract.buyer_name : contract.seller_name;
  const contractTypeLabel = getContractTypeLabel(contract.type, original.installation.country);

  return (
    <div className="d-flex flex-column gap-1">
      <div>
        <Badge label={contractTypeLabel} />
      </div>
      {counterpartName && (
        <div className="d-flex flex-row align-items-center">
          <div>{counterpartName}</div>
          <Icon
            name={contract.direction === 'sell' ? 'ArrowLeftCircle' : 'ArrowRightCircle'}
            className="ml-2"
            size={14}
          />
        </div>
      )}
    </div>
  );
};
