import fetchWrapper from '@utils/fetch';
import { formatSystemDate } from '@utils/formatters';

import { v2_non_injected_energies_path } from '@utils/routes';
import { NonInjectedEnergy } from '@utils/types/nonInjectedEnergy';

export const getNonInjectedEnergy = async (params: { start_time: Date | null; end_time: Date | null }) => {
  const response = await fetchWrapper(
    v2_non_injected_energies_path({
      params: {
        start_time: params.start_time ? formatSystemDate(params.start_time) : undefined,
        end_time: params.end_time ? formatSystemDate(params.end_time) : undefined,
      },
    }),
    {
      headers: { accept: 'application/json' },
    },
  );
  const nonInjectedEnergy: NonInjectedEnergy[] = await response.json();

  return nonInjectedEnergy;
};
