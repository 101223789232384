import React, { useMemo, useState } from 'react';
import { Table, TableActions, TableBody, TableHead, TablePageSizeSelect, TablePagination } from '@GDM/Table';
import {
  useReactTable,
  type ColumnDef,
  getSortedRowModel,
  getPaginationRowModel,
  getCoreRowModel,
  getFilteredRowModel,
  TableState,
} from '@tanstack/react-table';
import type { RenewalReminderConfiguration } from '@utils/types/event';

export type ConfigTableRow = Pick<RenewalReminderConfiguration, 'id' | 'days_before_contract_end'>;

export const ConfigTable = ({
  rows,
  columns,
  isLoading,
}: {
  columns: ColumnDef<ConfigTableRow>[];
  rows: ConfigTableRow[];
  isLoading: boolean;
}) => {
  const [{ pageIndex, pageSize }, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const state = useMemo<Partial<TableState>>(() => {
    return {
      pagination: { pageIndex, pageSize },
    };
  }, [pageIndex, pageSize]);

  const table = useReactTable({
    columns,
    data: rows,
    state,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const gotoPage = (pageIndex: number) => {
    setPagination((pagination) => ({ ...pagination, pageIndex }));
  };

  const setPageSize = (pageSize: number) => {
    setPagination((pagination) => ({ ...pagination, pageSize }));
  };

  return (
    <div className="p-3">
      <Table hasActions className="mt-3">
        <TableHead table={table} />
        <TableBody table={table} isLoading={isLoading} />
      </Table>
      {rows.length > 10 && (
        <TableActions>
          <TablePageSizeSelect pageSize={pageSize} setPageSize={setPageSize} totalEntries={rows.length} />
          <TablePagination pageCount={table.getPageCount()} gotoPage={gotoPage} />
        </TableActions>
      )}
    </div>
  );
};
