export const FIELD_SECTIONS = [
  ['energy', 'installation'],
  ['country', 'installation'],
  ['name', 'installation'],

  ['company_number', 'owner'],
  ['short_name', 'owner'],
  ['company_number', 'owner'],
  ['short_name', 'owner'],
  ['erp_code', 'owner'],
  ['bank', 'owner'],
  ['iban', 'owner'],
  ['swift_bic', 'owner'],
  ['invoicing_emails', 'owner'],
  ['owner_id', 'owner'],

  ['grid_id', 'meter'],
  ['data_provider_id', 'meter'],
  ['prm', 'meter'],
  ['numero', 'meter'],
  ['key', 'meter'],
  ['aiguillage', 'meter'],
  ['equipment_direction', 'meter'],
  ['ip', 'meter'],
  ['tcp_port', 'meter'],
  ['boitier_ip', 'meter'],
  ['serial_number', 'meter'],
  ['model', 'meter'],
  ['typecompteur', 'meter'],
  ['meter_pass_address', 'meter'],
  ['producteur', 'meter'],
  ['via_data_provider', 'meter'],
  ['via_direct_connection', 'meter'],
  ['data_provider_number', 'meter'],

  ['mandate_uuid', 'mandate'],
  ['mandate.signatory_name', 'mandate'],
  ['mandate.signatory_email', 'mandate'],
  ['mandate.sirets', 'mandate'],
  ['mandate.sign_request_type', 'mandate'],
  ['mandate.mandate_file', 'mandate'],

  ['meter_request_email', 'contact_info'],
  ['meter_request_name', 'contact_info'],
  ['meter_request_comment', 'contact_info'],
] as const;
