import React, { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { UpdateAction } from '../Cells/Actions';
import { EventTypeCell } from '../Cells/EventType';
import { RuleCell } from '../Cells/Rule';
import { ConfigTableRow } from '../ConfigTable';

export const useConfigTableColumns = ({ openEditModal }: { openEditModal: (id: number) => void }) => {
  const columns = useMemo<ColumnDef<ConfigTableRow>[]>(() => {
    return [
      {
        header: 'admin.alerts.reminder',
        accessorKey: 'reminder',
        cell: EventTypeCell,
      },
      {
        header: 'admin.alerts.rule',
        accessorKey: 'rule',
        cell: RuleCell,
      },
      {
        id: 'actions',
        cell: (props) => (
          <>
            <UpdateAction
              onClick={() => {
                openEditModal(props.row.original.id);
              }}
            />
          </>
        ),
      },
    ] satisfies ColumnDef<ConfigTableRow>[];
  }, [openEditModal]);

  return columns;
};
