import React from 'react';
import { Badge } from '@GDM/Badge';
import type { CellContext } from '@tanstack/react-table';
import { ConfigTableRow } from '../ConfigTable';
import { getRenewalReminderConfigurationLabel } from '../getRenewalReminderConfigurationLabel';

export const RuleCell = (props: CellContext<ConfigTableRow, unknown>) => {
  const label = getRenewalReminderConfigurationLabel(
    props.row.original.days_before_contract_end,
    false, // not light
  );

  return <Badge variant="default" label={label} />;
};
