import { CountryCode } from '@utils/types/countries';

export const isEnedisLike = (country: CountryCode | null | undefined, dataProviderName: string | null | undefined) => {
  if (country !== 'FR') return false;
  const name = (dataProviderName || '').toLowerCase();

  // Enedis and all French data providers
  // (except RTE, Electricite de Strasbourg or Synelva)
  if (['rte', 'électricité de strasbourg (és)', 'synelva'].includes(name)) return false;

  return true;
};

export const isEnedis = (country: CountryCode | null | undefined, dataProviderName: string | null | undefined) => {
  if (country !== 'FR') return false;
  const name = (dataProviderName || '').toLowerCase();

  return name === 'enedis';
};
