import React from 'react';
import { Badge } from '@GDM/Badge';
import useTranslation from '@hooks/useTranslation';
import { CellContext } from '@tanstack/react-table';
import { NonInjectedEnergy } from '@utils/types/nonInjectedEnergy';

export const EventTypeNonInjectedEnergyCell = ({
  row: { original },
}: React.PropsWithChildren<CellContext<NonInjectedEnergy, unknown>>) => {
  const { t } = useTranslation();
  const { event_type, impact_type } = original;

  if (!event_type && !impact_type) {
    return null;
  }

  return (
    <div className="d-flex flex-column align-items-start gap-1">
      {event_type && <div>{t(`monitoring.unavailabilities.${event_type}`)}</div>}
      {impact_type && <Badge label={t(`monitoring.unavailabilities.impact_type.${impact_type}`)} />}
    </div>
  );
};
