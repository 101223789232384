import type { RequestError, RequestErrorBody } from '@hooks/useRequest';
import fetchWrapper from '@utils/fetch';
import {
  update_user_v2_renewal_reminder_configurations_path,
  v2_renewal_reminder_configurations_path,
} from '@utils/routes';
import type { RenewalReminderConfiguration } from '@utils/types/event';

export const getRenewalReminderConfiguration = async () => {
  const response = await fetchWrapper(v2_renewal_reminder_configurations_path(), {
    method: 'GET',
    headers: { accept: 'application/json' },
  });
  const config: RenewalReminderConfiguration = await response.json();

  return config;
};

export type RenewalReminderConfigurationBody = { days_before_contract_end: number };

export const updateUserRenewalReminderConfiguration = async (body: RenewalReminderConfigurationBody) => {
  const response = await fetchWrapper(update_user_v2_renewal_reminder_configurations_path(), {
    body: JSON.stringify(body),
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  });

  let json: RenewalReminderConfiguration | RequestErrorBody;

  try {
    json = await response.json();
  } catch (error) {
    throw new Error('errors.unknown_error');
  }

  if (!response.ok) {
    const code = 'code' in json && json.code ? json.code : 'errors.unknown_error';
    const message = 'message' in json && json.message ? json.message : 'errors.unknown_error';
    const errors = 'errors' in json && json.errors ? (json.errors as RequestError[]) : [];

    throw { code, message, errors } satisfies RequestErrorBody;
  }

  return json as RenewalReminderConfiguration;
};
