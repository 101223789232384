import React from 'react';
import { Badge } from '@GDM/Badge';
import { Icon } from '@GDM/Icon';
import useTranslation from '@hooks/useTranslation';
import { CellContext } from '@tanstack/react-table';
import { formatDate } from '@utils/formatParseDate';
import dayjs from 'dayjs';
import { FieldValues } from 'react-hook-form';

export const DateRangeCell = <T extends FieldValues>({ row: { original } }: CellContext<T, unknown>) => {
  const { t } = useTranslation();

  const start = original.start_date || original.start_time;
  const end = original.end_date || original.end_time;

  if (!start || !end) {
    return null;
  }

  const numberOfMinutes = dayjs(end).diff(start, 'minutes');
  const numberOfHours = dayjs(end).diff(start, 'hours');
  const numberOfDays = dayjs(end).diff(start, 'days');

  return (
    <div>
      <div className="d-flex gap-2 align-items-center">
        {formatDate(new Date(start), 'YYYY-MM-DD HH:mm')}
        <Icon name="ArrowRight" size={14} />
        {formatDate(new Date(end), 'YYYY-MM-DD HH:mm')}
      </div>

      {numberOfMinutes >= 1 && numberOfHours < 1 && (
        <Badge
          label={t(numberOfMinutes === 1 ? 'common.duration.minute' : 'common.duration.minutes', {
            count: numberOfMinutes,
          })}
        />
      )}
      {numberOfHours >= 1 && numberOfDays < 1 && (
        <Badge
          label={t(numberOfHours === 1 ? 'common.duration.hour' : 'common.duration.hours', { count: numberOfHours })}
        />
      )}
      {numberOfDays >= 1 && (
        <Badge
          label={t(numberOfDays === 1 ? 'common.duration.day' : 'common.duration.days', { count: numberOfDays })}
        />
      )}
    </div>
  );
};
