import React from 'react';
import { Button } from '@GDM/Button';
import jsonToCsv, { type HeadCsv } from '@utils/json-to-csv';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';

export function SharingRightActions<T extends Record<string, unknown>>({
  csvData,
  csvHeader,
  fileNamePrefix,
}: {
  csvData: T[];
  csvHeader: HeadCsv<T>[];
  fileNamePrefix: string;
}) {
  const handleDownload = () => {
    const csv = jsonToCsv(csvData, csvHeader);
    const fileName = `${fileNamePrefix}_${dayjs().format('YYYY-MM-DD-HHmmss')}.csv`;

    fileDownload(csv, fileName, 'text/csv;charset=utf-8', '\uFEFF');
  };

  return (
    <div className="d-flex justify-content-end">
      <Button size="xxs" variant="primary-2" text="common.download" icon="Download" onClick={handleDownload} />
    </div>
  );
}
