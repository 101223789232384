import { tString } from '@hooks/useTranslation';
import { Locale } from '@utils/types/common-types';
import { EventType } from '@utils/types/event';

export const getDescription = (eventType: EventType, locale: Locale): string => {
  const t = tString(locale);
  switch (eventType) {
    case 'CommunicationFailure':
      return t('monitoring.alerts.modal.communication_failure');
    case 'ProductionStop':
      return t('monitoring.alerts.modal.production_stop');
    case 'UnderPerformance':
      return t('monitoring.alerts.modal.under_performance');
    case 'HighUnderPerformance':
      return t('monitoring.alerts.modal.high_under_performance');
    case 'OutOfRangeReactive':
      return t('monitoring.alerts.modal.out_of_range_reactive');
    case 'AnnualProductionExceedsCap':
      return t('monitoring.alerts.modal.prod_annual_exceeds_cap');
    case 'AnnualProductionExceedsDafr':
      return t('monitoring.alerts.modal.prod_annual_exceeds_dafr');
    case 'ProductionOnNegativePrice':
      return t('monitoring.alerts.modal.prod_on_negative_price');
    case 'InvoiceMissingData':
      return t('monitoring.alerts.modal.invoice_missing_data');
    case 'HighDiffPublishedProduction':
      return t('monitoring.alerts.modal.high_diff_published_production');
    case 'RenewalReminder':
      return t('monitoring.alerts.modal.renewal_reminder');
    default:
      return '';
  }
};
