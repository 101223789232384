import React from 'react';
import type { FormFilterType } from '@GDM/Filters/filters.types';

import type { EnergyType } from '@utils/types/common-types';
import { useForm } from 'react-hook-form';
import { DispatchProgramContent } from './DispatchProgramContent';
import { DispatchProgramFilters } from './DispatchProgramFilters';

export const DispatchProgram = () => {
  const filtersForm = useForm<DispatchProgramFilters>({
    defaultValues: {
      energy: 'all',
      status: [],
      bookUuid: [],
      installationName: [],
    },
  });
  const filters = filtersForm.watch();

  return <DispatchProgramContent filtersForm={filtersForm} filters={filters} />;
};

export type DispatchProgramFilters = FormFilterType<{
  energy: EnergyType | 'all';
  status: boolean[];
  bookUuid: string[];
  installationName: string[];
}>;
