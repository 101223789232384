import React, { useState } from 'react';
import { Tabs, type Tab } from '@GDM/Tabs';
import Page from '@pages/Page';
import { User } from '@utils/types/user';
import { DispatchProgram } from './DispatchProgram';
import { ENI } from './ENI/ENI';
import { UnavailabilitiesPageContent } from './UnavailabilitiesPageContent';

const Unavailabilities = (props: { user: User }) => {
  const hash = window.location.hash?.slice(1);
  const selectedTabFromHash = hashIsValid(hash) ? hash : null;
  const [selectedTab, setSelectedTab] = useState<UnavailabilitiesTab>(selectedTabFromHash || 'unavailabilities');
  const tabs: Tab<UnavailabilitiesTab>[] = [
    { id: 'unavailabilities', name: 'common.unavailabilities' },
    { id: 'dispatch_program', name: 'monitoring.unavailabilities.dispatch_program' },
    { id: 'eni', name: 'monitoring.unavailabilities.eni', permissions: ['display:non_injected_energy'] },
  ];

  const tabsContent: Record<UnavailabilitiesTab, JSX.Element> = {
    unavailabilities: <UnavailabilitiesPageContent />,
    dispatch_program: <DispatchProgram />,
    eni: <ENI />,
  };

  return (
    <Page user={props.user} locale={props.user.locale} title="common.unavailabilities">
      <Tabs tabs={tabs} onTabChange={setSelectedTab} />

      {tabsContent[selectedTab]}
    </Page>
  );
};

export type UnavailabilitiesTab = 'unavailabilities' | 'dispatch_program' | 'eni';

const hashIsValid = (hash: string): hash is UnavailabilitiesTab => {
  return ['unavailabilities', 'dispatch_program', 'eni'].includes(hash);
};

export default Unavailabilities;
