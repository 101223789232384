import React from 'react';
import { Button } from '@GDM/Button';
import { template_pdf_v2_mandates_path } from '@utils/routes';
import { MandateFormType } from '@utils/types/mandate';

const cleanSirets = (sirets: string) =>
  sirets
    ?.split(',')
    .flatMap((s) => s.split('\n'))
    .filter((s) => s !== '')
    .map((s) => s.replace(/^\s+|\s+$/g, ''));

export const DownloadTemplateButton = ({ form }: { form: MandateFormType }) => {
  const disabled = !form?.sirets || !form.signatory_name || !form.signatory_email;

  const onClick = () => {
    const sirets = form?.sirets ? cleanSirets(form.sirets) : [];
    const name = form?.signatory_name;
    const email = form?.signatory_email;
    window.location.href = template_pdf_v2_mandates_path({ name, email, sirets });
  };

  return (
    <Button
      variant="primary-2"
      className="mr-3"
      onClick={onClick}
      disabled={disabled}
      icon="Download"
      size="xs"
      text="common.download"
    />
  );
};
