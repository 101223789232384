import React from 'react';
import {
  updateUserRenewalReminderConfiguration,
  RenewalReminderConfigurationBody,
} from '@api/renewalReminderConfigurations';
import { Restricted } from '@components/Restricted';
import { Modal } from '@GDM/Modal';
import { Text } from '@GDM/Text';
import type { RequestErrorBody } from '@hooks/useRequest';
import { useMutation } from '@tanstack/react-query';
import type { RenewalReminderConfiguration } from '@utils/types/event';
import type { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { RenewalReminderSelect } from './RenewalReminderSelect';

export type FormData = {
  days_before_contract_end: RenewalReminderConfiguration['days_before_contract_end'];
};

export const ConfigForm = ({
  isOpen,
  toggle,
  onSuccess,
  selectedConfig,
}: {
  isOpen: boolean;
  toggle: () => void;
  onSuccess: (d: RenewalReminderConfiguration) => void;
  selectedConfig: RenewalReminderConfiguration | null;
}) => {
  const { control, handleSubmit } = useForm<FormData>({
    defaultValues: { days_before_contract_end: selectedConfig?.days_before_contract_end },
  });

  const mutation = useMutation<
    RenewalReminderConfiguration,
    AxiosError<RequestErrorBody>,
    RenewalReminderConfigurationBody
  >({
    mutationFn: updateUserRenewalReminderConfiguration,
    onSuccess: onSuccess,
  });

  const onSubmit = (data: FormData) => {
    mutation.mutate(data);
  };

  return (
    <Restricted permissions={['create:user_notification_settings', 'update:user_notification_settings']}>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        header="admin.alerts.edit_config"
        submitAction={handleSubmit(onSubmit)}
        isLoading={mutation.isPending}
        size="md"
      >
        <div className="d-flex flex-column gap-3">
          <Text text="admin.alerts.renew_contract_message" multiline />
          <RenewalReminderSelect control={control} />
        </div>
      </Modal>
    </Restricted>
  );
};
