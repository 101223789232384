import React, { useMemo } from 'react';
import { ListCell } from '@GDM/Table/Cells/ListCell';
import useTranslation from '@hooks/useTranslation';
import { ColumnDef, Row } from '@tanstack/react-table';
import { EventType } from '@utils/types/event';
import { DeleteAction, UpdateAction } from '../Cells/Actions';
import { EventableCell } from '../Cells/EventableCell';
import { NotificationsTableRow } from '../NotificationsTable';
import { NotificationsTableFilters } from '../types/notificationsFilters';

export const useNotificationTableColumns = ({
  openEditModal,
  openDeleteConfirmationModal,
}: {
  openEditModal: (id: number) => void;
  openDeleteConfirmationModal: (id: number) => void;
}) => {
  const { t } = useTranslation();

  const columns = useMemo<ColumnDef<NotificationsTableRow>[]>(() => {
    return [
      {
        header: 'common.email',
        accessorKey: 'email',
        filterFn: filterFn('email'),
      },
      {
        header: 'admin.alerts.scope',
        id: 'eventable',
        accessorKey: 'eventable.name',
        cell: EventableCell,
        filterFn: filterEventable,
      },
      {
        header: 'common.notifications',
        accessorKey: 'event_type',
        cell: (props) => (
          <ListCell list={props.row.original.event_type.map((type) => t(`admin.alerts.events.${type}`))} />
        ),
        filterFn: filterFn('event_type'),
      },
      {
        id: 'actions',
        cell: (props) => (
          <>
            <UpdateAction
              onClick={() => {
                openEditModal(props.row.original.id);
              }}
            />
            <DeleteAction
              onClick={() => {
                openDeleteConfirmationModal(props.row.original.id);
              }}
            />
          </>
        ),
      },
    ] satisfies ColumnDef<NotificationsTableRow>[];
  }, [openDeleteConfirmationModal, openEditModal, t]);

  return columns;
};

function filterFn(key: Exclude<keyof NotificationsTableFilters, 'eventable'>) {
  return (row: Row<NotificationsTableRow>, id: string, filterValue: EventType[] | string[]) => {
    return filterValue.length === 0 || filterValue.some((value) => (row.original[key] as string[]).includes(value));
  };
}

function filterEventable(row: Row<NotificationsTableRow>, id: string, filterValue: string[]) {
  return filterValue.length === 0 || filterValue.some((value) => (row.original.eventable?.name || '') === value);
}
