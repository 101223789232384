import React from 'react';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { Option } from '@utils/types/common-types';
import { Control } from 'react-hook-form';
import { getRenewalReminderConfigurationLabel } from '../getRenewalReminderConfigurationLabel';
import { FormData } from './ConfigForm';

export const RenewalReminderSelect = ({ control }: { control: Control<FormData> }) => {
  const options: Option<FormData['days_before_contract_end']>[] = [14, 1 * 30, 3 * 30, 4 * 30, 6 * 30].map(
    (days_before_contract_end) => ({
      value: days_before_contract_end,
      label: getRenewalReminderConfigurationLabel(
        days_before_contract_end,
        true, // light
      ),
    }),
  );

  return <ControlledSelect name="days_before_contract_end" control={control} options={options} />;
};
