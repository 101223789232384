import React, { useEffect } from 'react';
import { UserProvider } from '@components/providers/UserProvider';
import LocaleContext from '@context/locale.context';
import { datadogRum } from '@datadog/browser-rum';
import { reactPlugin } from '@datadog/browser-rum-react';
import { ErrorBoundary } from '@GDM/ErrorBoundary';
import { Icon, IconNames } from '@GDM/Icon';
import { Spinner } from '@GDM/Spinner';
import { Text } from '@GDM/Text';
import { TooltipRenderer } from '@GDM/Tooltip/TooltipRenderer';
import useTranslation from '@hooks/useTranslation';
import '@styles/GDM';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Locale } from '@utils/types/common-types';
import { User } from '@utils/types/user';
import classNames from 'classnames';
import styles from './page.module.scss';
import { PageWrapper } from './PageWrapper';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const PageChildren = ({
  children,
  error,
  isLoading,
  header,
  pageActions,
  layout = 'classic',
  titleIcon,
  locale,
  subtitle,
  title,
  user,
  mainClassName,
  fixedHeightContainer = false,
}: React.PropsWithChildren<PageProps>) => {
  const { t } = useTranslation(locale || user.locale);

  useEffect(() => {
    const allEnv: Record<string, string> = {
      'app.streem.eu': 'production',
      'app.staging.streem.eu': 'staging',
      'app.ekotchek.com': 'staging',
      'app.staging-bravo.streem.eu': 'staging',
    };

    const env = allEnv?.[window.location.host] || 'development';

    if (env !== 'development') {
      datadogRum.init({
        // There is no env variable in the front, and the following keys are not private
        applicationId: '37373583-a214-47d6-acca-d635c2f3d1ce',
        clientToken: 'pubfbdb6e5f1615e2a999f1ecdfb7d2e242',
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: 'datadoghq.eu',
        service: 'streemfront',
        env,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        defaultPrivacyLevel: 'allow',
        trackUserInteractions: true,
        trackingConsent: 'granted',
        // startSessionReplayRecordingManually: true,
        plugins: [reactPlugin()],
      });

      datadogRum.setUser({ email: user.email, id: user.uuid, name: user.name });
    }
  }, [user]);

  return (
    <UserProvider user={user}>
      <LocaleContext.Provider value={user?.locale || locale || 'fr'}>
        <PageWrapper fixedHeightContainer={fixedHeightContainer}>
          <header className={styles['header-wrapper']}>
            <div className={classNames(styles['title-wrapper'], 'mb-4')}>
              <h1 className={styles.title}>
                {!!titleIcon && <Icon name={titleIcon} size={24} className="mr-2" />}
                <span data-cy="page-title">{t(title)}</span>
                {isLoading && <Spinner size="sm" className={styles.spinner} />}
              </h1>

              {!!subtitle && (
                <div className={classNames(styles.subtitle, 'ml-3')}>
                  {typeof subtitle === 'string' ? t(subtitle) : subtitle}
                </div>
              )}

              {!!error && (
                <Text
                  type="danger"
                  icon="AlertCircle"
                  text={t(error, { country: user.main_country })}
                  className={styles.error}
                />
              )}
            </div>

            {!!pageActions && <div className={styles['page-actions']}>{pageActions}</div>}
          </header>

          {!!header && <div className={styles.header}>{header}</div>}

          <main
            className={classNames(
              styles.main,
              styles[layout],
              fixedHeightContainer && styles['fixed-height-content'],
              mainClassName,
            )}
          >
            <ErrorBoundary locale={locale || user?.locale}>{children}</ErrorBoundary>
          </main>

          <TooltipRenderer />
        </PageWrapper>
      </LocaleContext.Provider>
    </UserProvider>
  );
};

type PageProps = {
  locale?: Locale;
  error?: string | null;
  isLoading?: boolean;
  header?: React.ReactNode;
  pageActions?: React.ReactNode;
  subtitle?: React.ReactNode | string;
  title: string;
  titleIcon?: IconNames;
  user: User;
  layout?: 'classic' | 'no-background';
  mainClassName?: string;
  fixedHeightContainer?: boolean;
  useQueryClient?: boolean;
};

const Page = ({
  children,
  error,
  isLoading,
  header,
  pageActions,
  layout = 'classic',
  titleIcon,
  locale,
  subtitle,
  title,
  user,
  mainClassName,
  fixedHeightContainer = false,
  useQueryClient = true,
}: React.PropsWithChildren<PageProps>) => {
  const page = (
    <PageChildren
      {...{
        children,
        error,
        isLoading,
        header,
        pageActions,
        layout,
        titleIcon,
        locale,
        subtitle,
        title,
        user,
        mainClassName,
        fixedHeightContainer,
      }}
    />
  );

  return useQueryClient ? <QueryClientProvider client={queryClient}>{page}</QueryClientProvider> : page;
};

export type LayoutProps<T = object> = { user: User } & T;

export default Page;
