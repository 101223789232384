import React, { useState, useMemo } from 'react';
import { useMandatesContext } from '@context/Mandates.context';
import { Hr } from '@GDM/Hr';
import { Col, Row } from '@GDM/layout';
import { Text } from '@GDM/Text';
import { useGrids } from '@hooks/requests/useGrids';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import { useInstallationFormQuery } from '@pages/Installations/Installation/useInstallationFormQuery';
import { isEnedisLike } from '../../../isEnedisLike';
import { CompanyNumberInput } from './fields/CompanyNumberInput';
import { DownloadOriginalFile } from './fields/DownloadOriginalFile';
import { DownloadTemplateFile } from './fields/DownloadTemplateFile';
import { SignatoryEmailInput } from './fields/SignatoryEmailInput';
import { SignatoryNameInput } from './fields/SignatoryNameInput';
import { SignTypeSelect } from './fields/SignTypeSelect';
import { UploadFile } from './fields/UploadFile';
import MandateSelect from './MandateSelect/MandateSelect';

export const MandateSection = () => {
  const form = useInstallationForm();
  const country = form.watch('country');
  const signType = form.watch('mandate.sign_request_type');

  const { query } = useInstallationFormQuery();
  const installationCompanyNumber = query.data?.owner_company_number;

  const [isNewMandate, setIsNewMandate] = useState(false);
  const { data: mandates } = useMandatesContext();
  const mandateUuid = form.watch('mandate_uuid');
  const selectedMandate = useMemo(
    () => (mandates || []).find((mandate) => mandate.uuid === mandateUuid),
    [mandateUuid, mandates],
  );

  const openNewMandateForm = () => {
    setIsNewMandate(true);
    form.setValue('mandate_uuid', '');
    form.setValue('mandate.signatory_name', '');
    form.setValue('mandate.signatory_email', '');
    form.setValue('mandate.sirets', installationCompanyNumber || '');
    form.setValue('mandate.sign_request_type', null);
    form.setValue('mandate.mandate_file', null);
  };

  const closeNewMandateForm = () => {
    setIsNewMandate(false);
    form.setValue('mandate_uuid', query.data?.meter?.mandate_uuid || '');
  };

  const showForm = !selectedMandate && isNewMandate;

  const grids = useGrids();
  const grid_id = form.watch('grid_id');
  const selectedGridName = grids.data?.find(({ id }) => id === grid_id)?.name;
  const allowDownloadTemplate = signType === 'offline' && isEnedisLike(country, selectedGridName);

  const allowDownloadOriginal = !!selectedMandate && !!selectedMandate.url;

  // we show form errors at this section because we send meter request at this stage for Enedis like meters
  const errors = Object.entries(form.formState.errors).map((entry) => entry[1].message);
  const error = errors && errors.length > 0 ? errors[0] : undefined;

  return (
    <div className="p-3">
      {error && (
        <Text type="danger">
          <div dangerouslySetInnerHTML={{ __html: error }} />
        </Text>
      )}
      <MandateSelect
        openNewMandateForm={openNewMandateForm}
        closeNewMandateForm={closeNewMandateForm}
        isNewMandate={isNewMandate}
      />
      {allowDownloadOriginal && (
        <Row className="my-1">
          <Col md={3}>
            <DownloadOriginalFile mandate={selectedMandate} />
          </Col>
        </Row>
      )}
      {showForm && (
        <>
          <Hr />
          <Row className="my-1">
            <Col md={6}>
              <SignatoryNameInput disabled={!!selectedMandate} />
            </Col>
            <Col md={6}>
              <SignatoryEmailInput disabled={!!selectedMandate} />
            </Col>
          </Row>
          <Row className="my-1">
            <Col md={12}>
              <CompanyNumberInput disabled={!!selectedMandate} />
            </Col>
          </Row>
          <Row className="my-1">
            <Col md={12}>
              <SignTypeSelect disabled={!!selectedMandate} />
            </Col>
          </Row>
          {signType === 'offline' && (
            <Row className="my-2">
              <Col md={12}>
                <UploadFile />
              </Col>
            </Row>
          )}
          <Row className="my-2">
            <Col md={12}>
              {allowDownloadTemplate && <DownloadTemplateFile />}
              {signType === 'offline' && !allowDownloadTemplate && (
                <Text text="admin.installations.ops_will_send_template" multiline />
              )}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
