import React, { useContext } from 'react';
import { finishEvent } from '@api/events';
import { Restricted } from '@components/Restricted';
import { Button } from '@GDM/Button';
import { Text } from '@GDM/Text';
import type { RequestErrorBody } from '@hooks/useRequest';
import { useMutation } from '@tanstack/react-query';
import { CellContext } from '@tanstack/react-table';
import { Event } from '@utils/types/event';
import { filteredEventsContext } from '../filtered-events.context';

export const ReminderActionCell = ({ row: { original } }: CellContext<Event, unknown>) => {
  const { setAllEvents } = useContext(filteredEventsContext);

  const onSuccess = (event: Event) => {
    setAllEvents((events: Event[]) => [...events.filter((_event) => _event.id != event.id), event]);
  };

  const mutation = useMutation<Event, RequestErrorBody, { event_id: number }>({
    mutationFn: finishEvent,
    onSuccess: onSuccess,
  });

  const onClick = () => mutation.mutate({ event_id: original.id });

  if (original.finished) return <Text text="monitoring.alerts.closed_reminder" />;

  return (
    <div className="d-flex flex-row justify-content-between align-items-center">
      <Text text="monitoring.alerts.opened_reminder" />
      <Restricted permissions={['display:edit_monitoring', 'display:edit_salesfocus']}>
        <Button
          onClick={onClick}
          icon={mutation.isPending ? undefined : 'CheckCircle'}
          isLoading={mutation.isPending}
          disabled={mutation.isPending}
          variant="primary-2"
          tooltip="monitoring.alerts.close_reminder"
          floating
        />
      </Restricted>
    </div>
  );
};
