import { useDeferredValue, useMemo } from 'react';
import { DataTypeFiltersForm } from '@utils/types/dataFilter';
import { DataPeriod } from '@utils/types/dataPeriod';
import { Meter } from '@utils/types/meter';
import { TimeSeries } from '@utils/types/timeSeries';
import dayjs from 'dayjs';
import { UseFormReturn } from 'react-hook-form';
import { useChartData } from '../charts/series/useChartData';
import type { ProductionFilters } from '../Production';
import { DateRange, ProductionTimeSeriesRecord } from '../production.types';
import { getGapSizeOptions } from '../utils/getGapSizeOptions';

type ProductionChartProps = {
  availability?: TimeSeries;
  data?: Partial<ProductionTimeSeriesRecord>;
  dataPeriod: DataPeriod;
  inverterAll?: TimeSeries;
  onChartExtremesSet: (chartExtremes: [number, number]) => void;
  onDateRangeSelection: (dateRange: DateRange | null) => void;
  meter?: Meter;
  showForecast: boolean;
  form: UseFormReturn<DataTypeFiltersForm>;
  selectedMarketPriceCurve: ProductionFilters['selectedMarketPriceCurve'];
  marketPricesCurve: TimeSeries;
};

type ChartProps = {
  series: Highcharts.SeriesOptionsType[];
  options: Highcharts.Options;
};

export function useProductionChart({
  data,
  dataPeriod,
  availability,
  inverterAll,
  meter,
  showForecast,
  onChartExtremesSet,
  onDateRangeSelection,
  form,
  selectedMarketPriceCurve,
  marketPricesCurve,
}: ProductionChartProps): ChartProps {
  const isPeriodInMinutes = dataPeriod === 'minutes';
  const isDaily = dataPeriod === 'daily';
  const isMonthly = dataPeriod === 'monthly';
  const frequency = data?.rawActiveEnergyProduction?.freq_in_seconds ?? 900;

  const { series, yAxes } = useChartData({
    data,
    dataPeriod,
    availability,
    inverterAll,
    meter,
    showForecast,
    form,
    selectedMarketPriceCurve,
    marketPricesCurve,
  });

  // Keeps track of the previous (deferred) version of the series
  const deferredSeries = useDeferredValue(series);
  const haveSeriesChanged = deferredSeries !== series;

  const options: Highcharts.Options = useMemo(
    () => ({
      xAxis: {
        type: 'datetime',
        ordinal: false,
        gridLineWidth: isPeriodInMinutes ? 1 : 0,
        events: {
          afterSetExtremes: (e) => {
            if (e.min === undefined || e.max === undefined) return;

            onChartExtremesSet([e.min, e.max]);
          },
        },
        dateTimeLabelFormats: {
          second: '%H:%M',
          minute: '%H:%M',
          hour: '%H:%M',
          day: '%e %b',
        },
      },
      plotOptions: {
        series: {
          // If there are gaps in the data, and they are being connected instead of leaving an empty space,
          // this is what you have to look at.
          ...getGapSizeOptions({ isDaily, isMonthly, frequency }),

          // Only animating when the series changed prevents animating the chart on every render
          animation: haveSeriesChanged,
        },
      },
      yAxis: yAxes,
      chart: {
        events: {
          selection: function (event) {
            if (event?.xAxis?.[0]?.min && event?.xAxis?.[0]?.max) {
              onDateRangeSelection([dayjs(event?.xAxis?.[0]?.min).toDate(), dayjs(event?.xAxis?.[0]?.max).toDate()]);
            }

            return undefined;
          },
        },
      },
    }),
    [
      haveSeriesChanged,
      isPeriodInMinutes,
      onChartExtremesSet,
      onDateRangeSelection,
      yAxes,
      isDaily,
      isMonthly,
      frequency,
    ],
  );

  return {
    series,
    options,
  };
}
