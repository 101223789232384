import { Permission } from '@utils/types/permission';

export const TABS = {
  sell: [
    { id: 'loadcurve', name: 'monitoring.invoicing.tab_loadcurve' },
    { id: 'index', name: 'monitoring.invoicing.tab_index' },
    { id: 'yearly_regul', name: 'monitoring.invoicing.tab_yearly' },
    { id: 'capa', name: 'sales_management.invoice_capacity' },
    { id: 'rec', name: 'sales_management.invoice_rec' },
    { id: 'consumption_based', name: 'sales_management.invoice_consumption' },
    { id: 'invoice_metrics', name: 'sales_management.invoice_metrics' },
  ] as const,
  buy: [
    { id: 'power', name: 'common.electricity' },
    {
      id: 'land_leasing',
      name: 'sales_management.invoice_land_leasing',
      permissions: ['display:buying_opex_invoices'] as Permission[],
    },
  ] as const,
};

export type InvoiceTab = typeof TABS['sell'][number]['id'] | typeof TABS['buy'][number]['id'];
