import { getBuyerPlayers, getMarketPlayers, type GetMarketPlayersParams } from '@api/marketPlayers';
import { useQuery } from '@tanstack/react-query';
import type MarketPlayer from '@utils/types/market_player';
import type { RequestErrorBody } from '../useRequest';

export const useMarketPlayers = (params?: GetMarketPlayersParams) => {
  return useQuery<MarketPlayer[], RequestErrorBody>({
    queryKey: ['all_market_players', params],
    queryFn: () => getMarketPlayers(params),
  });
};

export const useBuyerPlayers = (with_unavailabilities_info?: boolean) => {
  return useQuery({
    queryKey: ['buyers'],
    queryFn: () => getBuyerPlayers(with_unavailabilities_info),
  });
};
