import React from 'react';
import { Badge } from '@GDM/Badge';
import { Icon } from '@GDM/Icon';
import { Tooltip } from '@GDM/Tooltip/Tooltip';
import useTranslation from '@hooks/useTranslation';
import { CellContext } from '@tanstack/react-table';
import getContractTypeLabel from '@utils/contract/getLabel';
import { MeterInvoice } from '@utils/types/meter-invoice';
import classNames from 'classnames';
import styles from './contract-cell.module.scss';

export const ContractCell = ({ row }: CellContext<MeterInvoice, unknown>) => {
  const { t } = useTranslation();
  let tooltip = '';

  tooltip += row.original.contract_card_i ? `${t('sales_management.card_i')} : ${row.original.contract_card_i}` : '';
  tooltip += row.original.contract_card_i && row.original.installation_erp_code ? '<br/>' : '';
  tooltip += row.original.contract_erp_code
    ? `${t('sales_management.erp_code')} : ${row.original.contract_erp_code}`
    : '';

  // TODO: HACK FOR SPANISH ACCOUNT
  const contractTypeLabel = getContractTypeLabel(row.original.contract_type, row.original.installation_country);

  const parcelName = row.original.contract_sub_period?.extra_params?.land_leasing_parcel_name;
  const counterpartName =
    row.original.contract_direction === 'sell'
      ? row.original.contract_buyer_short_name
      : row.original.contract_seller_short_name;

  return (
    <div>
      <div className="d-flex align-items-center">
        <Badge label={contractTypeLabel} />
        {row.original.power_augmentation_percentage && (
          <Badge className="ml-2" tooltip="sales_management.power_augmentation_title">
            {row.original.power_augmentation_percentage}%
          </Badge>
        )}
        <Icon
          size={14}
          name={row.original.installation_country || 'XX'}
          title={row.original.installation_country}
          className={classNames({ ['ml-2']: !!contractTypeLabel })}
        />
        {parcelName && (
          <div className="ml-2" style={{ fontWeight: 'lighter' }}>
            <span>{t('sales_management.land_leasing.parcel')}</span> : {parcelName}
          </div>
        )}
      </div>

      {counterpartName && (
        <Tooltip tooltip={tooltip}>
          <div className={styles['contract-cell-buyer']}>
            {row.original.contract_direction === 'sell' && <Icon name="ArrowRightCircle" className="mr-2" size={14} />}
            <div data-cy="cell-buyer-name">{counterpartName}</div>
            {row.original.contract_direction === 'buy' && <Icon name="ArrowRightCircle" className="ml-2" size={14} />}
          </div>
        </Tooltip>
      )}
    </div>
  );
};
