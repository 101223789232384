import { useEffect } from 'react';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import type { EnergyType, Option } from '@utils/types/common-types';
import type { CountryCode } from '@utils/types/countries';
import type { DispatchProgramSharingRight, SharingRight } from '@utils/types/unavailability';
import get from 'lodash/get';
import type { FieldValues, Path, PathValue, UseFormSetValue, UseFormWatch } from 'react-hook-form';

const getOptionsFromSharingRights = (sharingRights: SharingRight[] | DispatchProgramSharingRight[]) => {
  const allInstallationsMap = new Map<string, Option<string> & { energy: EnergyType }>();
  const allBooksMap = new Map<string, Option<string> & { energy: EnergyType }>();
  const allAggregatorsMap = new Map<string, Option<string>>();
  const countryMap = new Map<CountryCode, Option<CountryCode>>();

  sharingRights.forEach((unavailabilitySharingRight) => {
    const { market_player: marketPlayer, installation } = unavailabilitySharingRight;

    if (installation) {
      allInstallationsMap.set(installation.name, {
        value: installation.name,
        label: installation.name,
        energy: installation.energy,
      });

      if (installation.country) {
        countryMap.set(installation.country, { value: installation.country, label: installation.country });
      }

      installation.books.forEach((book) => {
        allBooksMap.set(book.name, { value: book.name, label: book.name, energy: 'book' });
      });
    }

    if (marketPlayer) {
      allAggregatorsMap.set(marketPlayer.long_name, { value: marketPlayer.long_name, label: marketPlayer.long_name });
    }
  });

  return {
    installationOptions: Array.from(allInstallationsMap.values()),
    countryOptions: Array.from(countryMap.values()),
    bookOptions: Array.from(allBooksMap.values()),
    aggregatorOptions: Array.from(allAggregatorsMap.values()),
  };
};

export const createGetOptionsFromSharingRights =
  (name: keyof ReturnType<typeof getOptionsFromSharingRights>) =>
  (sharingRights: SharingRight[] | DispatchProgramSharingRight[]) => {
    return getOptionsFromSharingRights(sharingRights)[name].sort(sortOptionsByLabelAsc);
  };

/**
 * This hook is to ensure that the `last_selected_filter` field is updated when a filter is changed, it is required in
 * dynamic filters
 */
export const useLastSelectedFilter = <T extends FieldValues>(watch: UseFormWatch<T>, setValue: UseFormSetValue<T>) => {
  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      if (name && name !== 'last_selected_filter' && type === 'change') {
        const value = get(values, name);
        const isValueValid = (Array.isArray(value) && value.length > 0) || value;

        setValue('last_selected_filter' as Path<T>, (name && isValueValid ? name : null) as PathValue<T, Path<T>>);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);
};

export const getInstallationOptions = createGetOptionsFromSharingRights('installationOptions');
export const getCountryOptions = createGetOptionsFromSharingRights('countryOptions') as (
  sharingRights: SharingRight[] | DispatchProgramSharingRight[],
) => Option<CountryCode>[];
export const getBookOptions = createGetOptionsFromSharingRights('bookOptions');
export const getAggregatorOptions = createGetOptionsFromSharingRights('aggregatorOptions');
