import React, { useMemo } from 'react';
import { Tabs, type Tab } from '@GDM/Tabs';
import { tabsContext } from '@GDM/Tabs/tabs.context';
import Page from '@pages/Page';
import { EventTabType } from '@utils/types/event';
import { User } from '@utils/types/user';
import { Alerts } from './Alerts';
import { Reminders } from './Reminders';

type Props = {
  user: User;
  type?: 'monitoring' | 'sales';
};

const Events = ({ user, type }: Props) => {
  const tabs = useMemo<Tab<EventTabType>[]>(() => {
    return [
      { id: 'alerts', name: 'common.alerts' },
      ...(type === 'sales' ? [{ id: 'reminders', name: 'admin.alerts.reminders' } as Tab<EventTabType>] : []),
    ];
  }, [type]);

  return (
    <Page user={user} title={type === 'sales' ? 'common.notifications' : 'common.alerts'}>
      <Tabs tabs={tabs}>
        <tabsContext.Consumer>
          {({ currentTab }) => (
            <>
              {currentTab === 'alerts' && <Alerts type={type} />}
              {currentTab === 'reminders' && <Reminders type={type} />}
            </>
          )}
        </tabsContext.Consumer>
      </Tabs>
    </Page>
  );
};

export default Events;
