import { useMemo } from 'react';
import type { ColumnDef } from '@tanstack/react-table';
import type { DispatchProgramSharingRight } from '@utils/types/unavailability';
import {
  bookColumn,
  countryColumn,
  credentialsColumn,
  formattedSharingStatusColumn,
  getActionColumn,
  installationColumn,
} from '../shared/columns';
import type { ToggleSharingRightsModal } from '../SharingRights/SharingRightTable';

export const useColumns = (toggle: ToggleSharingRightsModal) => {
  return useMemo(() => {
    const columns = [
      installationColumn as ColumnDef<DispatchProgramSharingRight>,
      countryColumn as ColumnDef<DispatchProgramSharingRight>,
      bookColumn as ColumnDef<DispatchProgramSharingRight>,
      formattedSharingStatusColumn as ColumnDef<DispatchProgramSharingRight>,
      credentialsColumn as ColumnDef<DispatchProgramSharingRight>,
      getActionColumn(toggle) as ColumnDef<DispatchProgramSharingRight>,
    ];

    return columns;
  }, [toggle]);
};
