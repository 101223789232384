import React, { useState } from 'react';
import { useMemo } from 'react';
import { Table, TableActions, TableBody, TableHead, TablePageSizeSelect, TablePagination } from '@GDM/Table';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnDef,
} from '@tanstack/react-table';
import { Event, EventTabType } from '@utils/types/event';
import classNames from 'classnames';
import getColumns from './getColumns';

type Props = {
  tabType: EventTabType;
  data: Event[];
  isLoading: boolean;
  isPortfolio?: boolean;
  light?: boolean;
};

export const EventsTable = ({ tabType, data, isLoading, isPortfolio, light }: Props) => {
  const columns: ColumnDef<Event>[] = useMemo(() => getColumns({ isPortfolio, tabType }), [tabType, isPortfolio]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });

  const table = useReactTable({
    columns,
    data,
    sortDescFirst: false,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    state: { pagination },
    onPaginationChange: setPagination,
  });

  const pageSize = pagination.pageSize;

  return (
    <>
      <Table data-cy="alerts-table">
        <TableHead table={table} />
        <TableBody table={table} isLoading={isLoading} data-cy="event-table" />
      </Table>

      {data.length > pageSize && (
        <TableActions className={classNames(light && 'p-0')}>
          <TablePageSizeSelect pageSize={pageSize} setPageSize={table.setPageSize} totalEntries={data.length} />
          <TablePagination pageCount={table.getPageCount()} gotoPage={table.setPageIndex} />
        </TableActions>
      )}
    </>
  );
};
