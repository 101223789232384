import React, { useMemo, useState } from 'react';
import { useUser } from '@context/User.context';
import { DatePicker } from '@GDM/DatePicker';
import { FilterContainer } from '@GDM/Filters';
import { DataTable } from '@GDM/Table/DataTable/DataTable';
import { Column } from '@GDM/Table/DataTable/DataTable.types';
import { useNonInjectedEnergy } from '@hooks/requests/useNonInjectedEnergy';
import { getSymbol } from '@utils/currency/getSymbol';
import type { Currency } from '@utils/types/currency';
import { NonInjectedEnergy } from '@utils/types/nonInjectedEnergy';
import { User } from '@utils/types/user';
import dayjs from 'dayjs';
import { EventTypeNonInjectedEnergyCell } from '../cells/EventTypeNonInjectedEnergyCell';
import { SourceCell } from '../cells/SourceCell';
import { TimeCell } from '../cells/TimeCell';

export const ENI = () => {
  const user = useUser();
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    dayjs().subtract(3, 'day').toDate(),
    dayjs().add(1, 'month').toDate(),
  ]);
  const [startTime, setStartTime] = useState(dateRange[0]);
  const [endTime, setEndTime] = useState(dateRange[1]);

  if (dateRange[0] && dateRange[1] && (startTime !== dateRange[0] || endTime !== dateRange[1])) {
    setStartTime(dateRange[0]);
    setEndTime(dateRange[1]);
  }

  const { data: nonInjectedEnergies, isPending } = useNonInjectedEnergy({ start_time: startTime, end_time: endTime });

  const currency = nonInjectedEnergies?.[0]?.trade.currency;

  const columns = useColumns(currency || 'EUR', user);

  return (
    <DataTable
      className="mt-3"
      columns={columns}
      data={nonInjectedEnergies || []}
      isLoading={isPending}
      filtersPrefix={
        <FilterContainer size="datepicker">
          <DatePicker selectsRange onChange={setDateRange} startDate={dateRange[0]} endDate={dateRange[1]} />
        </FilterContainer>
      }
    />
  );
};

const useColumns = (currency: Currency, user: User) => {
  const columns: Column<NonInjectedEnergy>[] = useMemo(() => {
    return [
      {
        id: 'installation_name',
        dataType: 'installation',
        accessorKey: 'installation.name',
        filterType: 'multi-select',
        header: 'common.installation',
      },
      {
        id: 'book',
        dataType: 'book',
        accessorKey: 'installation.books',
        filterType: 'multi-select',
        header: 'common.book',
        hide: true,
      },
      {
        id: 'start_time',
        dataType: 'daterange',
        header: 'common.date',
        accessorKey: 'start_time',
      },
      {
        id: 'eni',
        dataType: 'number',
        tooltip: 'MWh',
        accessorKey: 'quantity',
        header: 'monitoring.unavailabilities.eni_quantity',
      },
      {
        id: 'buy_price',
        dataType: 'currency',
        tooltip: `${getSymbol(currency)}/MWh`,
        accessorKey: 'price',
        header: 'monitoring.unavailabilities.buy_price',
      },
      {
        id: 'total_compensation',
        dataType: 'currency',
        tooltip: getSymbol(currency),
        accessorFn: (row) => (row.trade.price && row.trade.power ? row.trade.price * row.trade.power : null),
        header: 'monitoring.unavailabilities.total_compensation',
      },
      {
        id: 'max_injected_power',
        dataType: 'number',
        accessorKey: 'max_injected_power',
        header: 'monitoring.unavailabilities.max_injected_power',
        tooltip: 'kW',
      },
      {
        id: 'eni_status',
        dataType: 'string',
        accessorKey: 'status',
        header: 'monitoring.unavailabilities.eni_status_label',
        filterType: 'multi-select',
      },
      {
        id: 'p_max',
        dataType: 'number',
        accessorKey: 'p_max',
        header: 'monitoring.unavailabilities.p_max',
        tooltip: 'MW',
      },
      {
        id: 'energy',
        dataType: 'energy',
        filterType: 'radio',
        filterDefaultValue: 'all',
        accessorKey: 'installation.energy',
        hide: true,
      },
      {
        id: 'event_type',
        dataType: 'string',
        header: 'monitoring.unavailabilities.event_type',
        accessorKey: 'event_type',
        filterType: 'multi-select',
        filterOptionsLabelPrefix: 'monitoring.unavailabilities',
        customCell: EventTypeNonInjectedEnergyCell,
      },
      {
        id: 'impact_type',
        dataType: 'string',
        header: 'monitoring.unavailabilities.impact_type.label',
        accessorKey: 'impact_type',
        filterType: 'multi-select',
        filterOptionsLabelPrefix: 'monitoring.unavailabilities.impact_type',
        hide: true,
      },
      {
        id: 'source',
        header: 'monitoring.invoicing.source',
        dataType: 'string',
        accessorKey: 'source',
        customCell: SourceCell,
        filterType: 'multi-select',
        filterOptionsLabelPrefix: 'monitoring.unavailabilities.sources',
      },
      {
        id: 'comment',
        header: 'monitoring.unavailabilities.comment',
        dataType: 'string',
        accessorKey: 'comment',
      },
      {
        id: 'aggregator',
        header:
          user.account_type == 'aggregator'
            ? 'monitoring.unavailabilities.producer'
            : 'monitoring.unavailabilities.aggregator',
        tooltip: 'monitoring.unavailabilities.warnings.counterpart_null_case',
        accessorKey: 'aggregator',
        dataType: 'string',
        filterType: 'multi-select',
      },
      {
        id: 'updated_at',
        header: 'common.updated_at',
        dataType: 'datetime',
        accessorKey: 'updated_at',
        customCell: TimeCell,
      },
    ];
  }, [currency, user]);

  return columns;
};
