import React from 'react';
import { Restricted } from '@components/Restricted';
import { Button } from '@GDM/Button';

export const UpdateAction = ({ onClick }: { onClick: () => void }) => {
  return (
    <Restricted permissions={['update:user_notification_settings']}>
      <Button floating icon="Edit3" onClick={onClick} />
    </Restricted>
  );
};
