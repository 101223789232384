import React, { InputHTMLAttributes } from 'react';
import { Input } from '@GDM/forms';
import dayjs from 'dayjs';
import styles from './date-picker.module.scss';

export const TimePicker = ({
  value,
  onChange,
}: InputHTMLAttributes<HTMLTimeElement> & {
  onChange?: (e: string) => void;
}) => {
  return (
    <Input
      className={styles['time-picker']}
      value={value}
      type="time"
      onChange={(e) => onChange?.(e.target.value)}
      autoComplete="off"
      full
    />
  );
};

export const TimePickerRange = ({
  startDate,
  endDate,
  onInternalChange,
}: {
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
  /**
   * Cannot use onChange as it overriden by react-datepicker, but we cannot use the built-in onChange
   * because it only works with a single date
   */
  onInternalChange?: (date: [Date | null, Date | null]) => void;
}) => {
  return (
    <div>
      <Input
        className={styles['time-picker']}
        value={startDate ? dayjs(startDate).format('HH:mm') : undefined}
        type="time"
        onChange={(e) => {
          const [hour, minute] = e.currentTarget.value.split(':');

          if (hour && minute) {
            const startDateWithTime = dayjs(startDate).set('hour', +hour).set('minute', +minute).toDate();

            onInternalChange?.([startDateWithTime, dayjs(endDate).toDate()]);
          }
        }}
        autoComplete="off"
        full
        label="common.start"
      />
      <Input
        className={styles['time-picker']}
        value={endDate ? dayjs(endDate).format('HH:mm') : undefined}
        type="time"
        onChange={(e) => {
          const [hour, minute] = e.currentTarget.value.split(':');

          if (hour && minute) {
            const endDateWithTime = dayjs(endDate).set('hour', +hour).set('minute', +minute).set('seconds', 0).toDate();

            onInternalChange?.([dayjs(startDate).toDate(), endDateWithTime]);
          }
        }}
        autoComplete="off"
        full
        label="common.end"
      />
    </div>
  );
};
