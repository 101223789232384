import React from 'react';
import { useUser } from '@context/User.context';
import { Badge } from '@GDM/Badge';
import useTranslation from '@hooks/useTranslation';
import { getSymbol } from '@utils/currency/getSymbol';
import { isANumber } from '@utils/isANumber';
import { Contract } from '@utils/types/contract';
import styles from '../../../contracts.module.scss';

export const RefPriceTag = ({
  currency,
  type,
  period,
}: {
  type?: Contract['type'];
  period?: Contract['contract_sub_periods'][number];
  currency: Contract['currency'];
}) => {
  const { locale } = useUser();
  const { t } = useTranslation();

  const formatPrice = (value: number): string =>
    new Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);

  if (!period) return null;

  const { display_price, price_type, gc_price } = period;
  const price_unit = !gc_price ? 'MWh' : 'MW';

  if (type === 'ContractOa') {
    return (
      <>
        {t('common.ref_label')}&nbsp;
        <Badge>FeedInTarif</Badge>&nbsp;
        <span className={styles.price}>
          {isANumber(display_price) && (
            <>
              <b>{formatPrice(display_price)}</b>&nbsp;
              <span>
                {getSymbol(currency)}/{price_unit}
              </span>
            </>
          )}
        </span>
      </>
    );
  } else if (type === 'ContractCrEdf') {
    return (
      <>
        {t('common.ref_label')}&nbsp;
        <Badge>M0</Badge>{' '}
        <span className={styles.price}>
          {(display_price || display_price === 0) && (
            <>
              <b>{formatPrice(display_price)}</b>&nbsp;
              <span>
                {getSymbol(currency)}/{price_unit}
              </span>
            </>
          )}
        </span>
      </>
    );
  } else if (type === 'ContractLandLeasing') {
    return (
      <>
        {t('common.ref_label')}&nbsp;
        <Badge>{t('sales_management.land_leasing.sub_type.rent_based')}</Badge>&nbsp;
        <span className={styles.price}>
          {isANumber(display_price) && (
            <>
              <b>{formatPrice(display_price)}</b>&nbsp;
              <span>
                {getSymbol(currency)}/{t('sales_management.per_turbine_year')}
              </span>
            </>
          )}
        </span>
      </>
    );
  } else if (price_type === 'spot' || price_type === 'm0') {
    const badgeLabelMap = {
      spot: 'sales_management.price.spot',
      m0: 'sales_management.price.m0',
    };

    const badgeLabel = badgeLabelMap[price_type];

    return (
      <>
        {t('common.ref_label')}&nbsp;
        <Badge label={badgeLabel} />
      </>
    );
  } else if (price_type === 'fixed' || price_type === 'indexed') {
    const badgeLabelMap = {
      fixed: 'sales_management.price.fix',
      indexed: 'sales_management.price.indexed',
    };

    const badgeLabel = badgeLabelMap[price_type];

    return (
      <>
        {t('common.ref_label')}&nbsp;
        <Badge label={badgeLabel} />
        &nbsp;
        <span className={styles.price}>
          {(display_price || display_price === 0) && (
            <>
              <b>{formatPrice(display_price)}</b>&nbsp;
              <span>
                {getSymbol(currency)}/{price_unit}
              </span>
            </>
          )}
        </span>
      </>
    );
  }

  return null;
};
