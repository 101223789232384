import React from 'react';
import type { FormFilterType } from '@GDM/Filters/filters.types';
import type { CountryCode } from '@utils/types/countries';
import type { SharingRight } from '@utils/types/unavailability';
import { useForm } from 'react-hook-form';
import { DispatchProgramSharingRightTable } from './DispatchProgramSharingRightTable';

export const DispatchProgram = () => {
  const filtersForm = useForm<DispatchProgramSharingRightsFiltersForm>({
    defaultValues: { installation: [], country: [], book: [] },
  });
  const filters = filtersForm.watch();

  return <DispatchProgramSharingRightTable filters={filters} filtersForm={filtersForm} />;
};

export type DispatchProgramSharingRightsFiltersForm = FormFilterType<{
  installation: string[];
  country: CountryCode[];
  book: string[];
  formatted_sharing_status: SharingRight['sharing_status'] | null;
}>;
