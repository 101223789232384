import { useCallback, useState } from 'react';
import axiosInstance from '@utils/axiosInstance';
import { formatSystemDate } from '@utils/formatters';
import { pdf_v2_export_reports_path } from '@utils/routes';
import { CountryCode } from '@utils/types/countries';
import { Currency } from '@utils/types/currency';
import fileDownload from 'js-file-download';

const useDownloadPdf = ({
  selectedDate,
  selectedBook,
  selectedCountry,
  selectedMarketPlayers,
  selectedCurrency,
}: {
  selectedDate: Date;
  selectedBook: string | null;
  selectedCountry: CountryCode | null;
  selectedMarketPlayers?: string[] | null;
  selectedCurrency: Currency | null;
}): {
  loading: boolean;
  error: string | null;
  downloadPdf: () => void;
} => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const downloadPdf = useCallback(() => {
    const download = async () => {
      setLoading(true);

      try {
        const req = await axiosInstance(false).get(
          pdf_v2_export_reports_path({
            date: formatSystemDate(selectedDate),
            book_uuid: selectedBook,
            country: selectedCountry,
            market_player_ids: selectedMarketPlayers,
            currency: selectedCurrency,
          }),
          { responseType: 'blob' },
        );

        if (req.status === 200) {
          const fileName = req.headers['content-disposition'].split('"')[1];
          fileDownload(req.data, fileName);
        }
      } catch (error) {
        setError('Something went wrong');
      } finally {
        setLoading(false);
      }
    };

    download();
  }, [selectedDate, selectedBook, selectedMarketPlayers, selectedCountry, selectedCurrency]);

  return { loading, downloadPdf, error };
};

export default useDownloadPdf;
