import React from 'react';
import { useState } from 'react';
import { EventsFilters } from '@components/Events/EventsFilters';
import { EventsPageWrapper } from '@components/Events/EventsPageWrapper';
import { FilteredEventsProvider } from '@components/Events/FilteredEventsProvider';
import { UserProvider } from '@components/providers/UserProvider';
import { Props as UseEventsProps } from '@hooks/requests/useEvents';
import { User } from '@utils/types/user';

type Props = {
  eventableId: UseEventsProps['eventableId'];
  eventableType: UseEventsProps['eventableType'];
  user: User;
  type?: 'monitoring' | 'sales';
};

export const Events = ({ eventableId, eventableType, user, type }: Props) => {
  const [infoModalIsOpen, setInfoModalIsOpen] = useState(false);
  const hasMonitoringPermission = user.permissions.includes('display:view_monitoring');
  const hasSalesPermission = user.permissions.includes('display:view_salesfocus');

  return (
    <FilteredEventsProvider
      hasMonitoringPermission={hasMonitoringPermission}
      hasSalesPermission={hasSalesPermission}
      type={type}
      tabType="alerts"
    >
      <UserProvider user={user}>
        <EventsFilters light tabType="alerts" />
        <EventsPageWrapper
          tabType="alerts"
          eventableId={eventableId}
          eventableType={eventableType}
          eventTypes={null}
          infoModalIsOpen={infoModalIsOpen}
          setInfoModalIsOpen={setInfoModalIsOpen}
          light
        />
      </UserProvider>
    </FilteredEventsProvider>
  );
};
