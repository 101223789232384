import React, { useCallback, useMemo, useState } from 'react';
import { getRenewalReminderConfiguration } from '@api/renewalReminderConfigurations';
import type { RequestErrorBody } from '@hooks/useRequest';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { RenewalReminderConfiguration } from '@utils/types/event';
import type { AxiosError } from 'axios';
import { ConfigForm } from './ConfigForm';
import { ConfigTable, ConfigTableRow } from './ConfigTable';
import { useConfigTableColumns } from './hooks/useConfigTableColumns';

export const NotificationsConfigs = () => {
  const [selectedConfigId, setSelectedConfigId] = useState<number | null>(null);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  const queryClient = useQueryClient();
  const queryKey = ['renewal_reminder_configuration'];
  const query = useQuery<RenewalReminderConfiguration, AxiosError<RequestErrorBody>>({
    queryKey,
    queryFn: getRenewalReminderConfiguration,
  });

  const openEditModal = useCallback((id: number | null) => {
    setSelectedConfigId(id);
    setIsFormModalOpen(true);
  }, []);

  const closeEditModal = useCallback(() => {
    setSelectedConfigId(null);
    setIsFormModalOpen(false);
  }, []);

  const rows = useMemo<ConfigTableRow[]>(() => (query.data ? [query.data] : []), [query.data]);
  const columns = useConfigTableColumns({ openEditModal });
  const selectedConfig = rows.find((config) => config.id === selectedConfigId) || null;

  const onSuccess = (config: RenewalReminderConfiguration) => {
    queryClient.setQueryData(queryKey, () => config);
    closeEditModal();
  };

  return (
    <div className="mt-3 d-flex flex-column">
      <ConfigForm
        toggle={closeEditModal}
        isOpen={isFormModalOpen}
        selectedConfig={selectedConfig}
        onSuccess={onSuccess}
      />
      <ConfigTable rows={rows} columns={columns} isLoading={query.isPending} />
    </div>
  );
};
