import React, { useMemo } from 'react';
import { Tabs, type Tab } from '@GDM/Tabs';
import { tabsContext } from '@GDM/Tabs/tabs.context';
import Page from '@pages/Page';
import type { User } from '@utils/types/user';
import { NotificationsConfigs } from './NotificationsConfigs';
import { NotificationsSettings } from './NotificationsSettings';

export const Notifications = ({ user }: { user: User }) => {
  const tabs = useMemo<Tab<'notifications' | 'emails' | 'configs'>[]>(() => {
    return [
      { id: 'emails', name: 'admin.alerts.emails', permissions: ['index:user_notification_settings'] },
      { id: 'configs', name: 'admin.alerts.configurations', permissions: ['index:user_notification_settings'] },
    ];
  }, []);

  return (
    <Page title="common.notifications" user={user}>
      <Tabs tabs={tabs}>
        <tabsContext.Consumer>
          {({ currentTab }) => (
            <>
              {currentTab === 'emails' && <NotificationsSettings />}
              {currentTab === 'configs' && <NotificationsConfigs />}
            </>
          )}
        </tabsContext.Consumer>
      </Tabs>
    </Page>
  );
};
