import React, { useState } from 'react';
import { useInvoicesByType } from '@hooks/requests/meter-invoices/useInvoicesByType';
import type { Contract } from '@utils/types/contract';
import dayjs from 'dayjs';
import InvoicePageWrapper from '../InvoicePageWrapper';

const LandLeasingInvoices = ({ direction }: { direction: Contract['direction'] }) => {
  const [date, setDate] = useState<Date>(dayjs().subtract(1, 'month').toDate());
  const req = useInvoicesByType('land_leasing', direction, date);

  const handleDateChange = (date: Date | null) => {
    setDate(date ?? dayjs().subtract(1, 'month').toDate());
  };

  return (
    <InvoicePageWrapper
      invoiceType="land_leasing"
      invoices={req.data ?? []}
      isLoading={req.isPending}
      startDate={date}
      onDateChange={handleDateChange}
      isDateRange={false}
      yearlyDate
      showDetailsButton={false}
      direction={direction}
      actionDirection={direction}
    />
  );
};

export default LandLeasingInvoices;
