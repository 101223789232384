export const CONTRACT_TYPES = [
  'ContractCapa',
  'ContractCapaProxy',
  'ContractCppa',
  'ContractCr',
  'ContractCrEdf',
  'ContractGc',
  'ContractGo',
  'ContractGo-LIGHT',
  'ContractLandLeasing',
  'ContractOa',
  'ContractSoa',
  'ContractSoa-LIGHT',
  'ContractSwap',
  'ContractSwapPl',
  'ContractSwapUk',
  'ContractSwapIe',
  'ContractSupplyAuxiliaries',
  'ContractSwapIt',
  'ContractSupplyUppa',
  'ContractSupplyCppa',
] as const;

/**
 * Returns a list of contract types that exist in the backend and have a corresponding 'light' version in the front-end.
 *
 * _e.g._ `['ContractGo', 'ContractSoa']`
 */
export const LIGHT_CONTRACT_TYPES = CONTRACT_TYPES.filter((type) => type.includes('-LIGHT')).map((t) =>
  t.replace('-LIGHT', ''),
) as typeof CONTRACT_TYPES[number][];
