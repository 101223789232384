import React from 'react';
import { Button } from '@GDM/Button';
import { download_v2_mandate_path } from '@utils/routes';
import { Mandate } from '@utils/types/mandate';

export const DownloadOriginalButton = ({ selectedMandate }: { selectedMandate: Mandate }) => {
  const disabled = !selectedMandate.url;
  const onClick = () => {
    window.location.href = download_v2_mandate_path(selectedMandate.uuid);
  };

  return (
    <Button
      variant="primary-2"
      onClick={onClick}
      disabled={disabled}
      className="mr-3"
      icon="Download"
      size="xs"
      text="common.download"
    />
  );
};
