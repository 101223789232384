import React, { useCallback, useMemo, useState } from 'react';
import { Restricted } from '@components/Restricted';
import useBooks from '@hooks/requests/useBooks';
import { useUnavailabilities } from '@hooks/requests/useUnavailabilities';
import type { Unavailability } from '@utils/types/unavailability';
import { Actions } from './Actions';
import { CancelModal } from './CancelModal';
import { CreateModal } from './CreateModal/CreateModal';
import { defaultValues, useUnavailabilityForm } from './CreateModal/useUnavailabilityForm';
import { filterUnavailabilities, useFilters } from './Filters/useFilters';
import { Header } from './Header';
import { UnavailabilityTable } from './UnavailabilityTable';

export const UnavailabilitiesPageContent = () => {
  const { data: books } = useBooks();

  const { form: filtersForm, filteredInstallations } = useFilters(books || []);
  const filters = filtersForm.watch();

  const [startDate, setStartDate] = useState(filters.dateRange[0]);
  const [endDate, setEndDate] = useState(filters.dateRange[1]);
  if (
    filters.dateRange[0] &&
    filters.dateRange[1] &&
    (startDate !== filters.dateRange[0] || endDate !== filters.dateRange[1])
  ) {
    setStartDate(filters.dateRange[0]);
    setEndDate(filters.dateRange[1]);
  }

  const { data: unavailabilities, isPending } = useUnavailabilities({
    start_date: startDate,
    end_date: endDate,
  });

  const filteredUnavailabilities = useMemo(
    () => filterUnavailabilities(filters, unavailabilities || [], books || []),
    [filters, unavailabilities, books],
  );
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [selectedUnavailabilityId, setSelectedUnavailabilityId] = useState<Unavailability['uuid'] | null>(null);

  const selectedUnavailability = useMemo(
    () => unavailabilities?.find((u) => u.uuid === selectedUnavailabilityId),
    [selectedUnavailabilityId, unavailabilities],
  );

  const modalForm = useUnavailabilityForm(selectedUnavailability || null);
  const { reset } = modalForm;

  const toggleFormModal = useCallback<ToggleModal>(
    (id) => {
      setIsFormModalOpen((open) => {
        setSelectedUnavailabilityId(id);

        if (!open) {
          reset(defaultValues);
        }

        return !open;
      });
    },
    [reset],
  );

  const toggleCancelModal = useCallback<ToggleModal>((id) => {
    setIsCancelModalOpen((open) => {
      setSelectedUnavailabilityId(id);

      return !open;
    });
  }, []);

  return (
    <>
      <Header
        unavailabilities={unavailabilities || []}
        filteredInstallations={filteredInstallations}
        filteredUnavailabilities={filteredUnavailabilities}
        filtersForm={filtersForm}
        isLoading={isPending}
        startDate={startDate}
        endDate={endDate}
      />
      <Restricted permissions={['display:unavailability']}>
        {isFormModalOpen && (
          <CreateModal
            selectedUnavailability={selectedUnavailability || null}
            isOpen={isFormModalOpen}
            toggle={toggleFormModal}
            form={modalForm}
            startDate={startDate}
            endDate={endDate}
          />
        )}
        <CancelModal
          isOpen={isCancelModalOpen}
          toggle={toggleCancelModal}
          selectedUnavailability={selectedUnavailability || null}
          startDate={startDate}
          endDate={endDate}
        />

        <div className="p-3">
          <Actions
            filteredUnavailabilities={filteredUnavailabilities}
            filtersForm={filtersForm}
            toggleFormModal={toggleFormModal}
          />
        </div>

        <UnavailabilityTable
          unavailabilities={filteredUnavailabilities}
          onEditClick={toggleFormModal}
          onCancelClick={toggleCancelModal}
          isLoading={isPending}
        />
      </Restricted>
    </>
  );
};

export type ToggleModal = (id: Unavailability['uuid'] | null) => void;
