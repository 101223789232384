import type { RequestError } from '@hooks/useRequest';
import fetchWrapper from '@utils/fetch';
import { recordToFormData } from '@utils/recordToFormData';
import { v2_dispatch_program_sharing_right_path, v2_dispatch_program_sharing_rights_path } from '@utils/routes';
import type { DispatchProgramSharingRight } from '@utils/types/unavailability';

export const getDispatchProgramSharingRights = async () => {
  const response = await fetchWrapper(v2_dispatch_program_sharing_rights_path());
  const sharingRights: DispatchProgramSharingRight[] = await response.json();

  return sharingRights;
};

export type DispatchProgramSharingRightBody = Pick<DispatchProgramSharingRight, 'sharing_status'>;

export const updateDispatchProgramSharingRight = async (
  body: DispatchProgramSharingRightBody,
  id: DispatchProgramSharingRight['id'],
) => {
  const response = await fetchWrapper(v2_dispatch_program_sharing_right_path(id), {
    body: recordToFormData(body),
    method: 'PUT',
  });

  const json: DispatchProgramSharingRight | RequestError = await response.json();

  if (!response.ok) {
    const errorMessage = 'message' in json ? json.message : 'errors.unknown_error';

    throw new Error(errorMessage);
  }

  return json as DispatchProgramSharingRight;
};
