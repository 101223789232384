import fetchWrapper from '@utils/fetch';
import { v2_dispatch_program_sharing_statuses_path } from '@utils/routes';
import type { DispatchProgramSharingStatus } from '@utils/types/unavailability';

export const getDispatchProgramSharingStatuses = async () => {
  const response = await fetchWrapper(v2_dispatch_program_sharing_statuses_path());
  const sharingRights: DispatchProgramSharingStatus[] = await response.json();

  return sharingRights;
};
