import React, { useMemo, useState } from 'react';
import { useUser } from '@context/User.context';
import { Table, TableHead, TableBody, TableActions, TablePageSizeSelect, TablePagination } from '@GDM/Table';
import { getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import type { Unavailability } from '@utils/types/unavailability';
import { ColumnWithClassName, getColumns } from './getColumns';
import styles from './unavailabilities.module.scss';
import type { ToggleModal } from './UnavailabilitiesPageContent';

export const UnavailabilityTable = ({
  unavailabilities,
  onEditClick,
  onCancelClick,
  isLoading,
}: {
  unavailabilities: Unavailability[];
  onEditClick: ToggleModal;
  onCancelClick: ToggleModal;
  isLoading: boolean;
}) => {
  const user = useUser();
  const columns = useMemo<ColumnWithClassName[]>(
    () => getColumns({ user, onEditClick, onCancelClick }),
    [user, onEditClick, onCancelClick],
  );
  const data = useMemo(() => unavailabilities, [unavailabilities]);

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 25 });

  const table = useReactTable({
    columns,
    data,
    sortDescFirst: false,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    state: { pagination },
    initialState: { sorting: [{ id: 'start_date', desc: false }] },
  });

  const pageSize = pagination.pageSize;

  return (
    <div className={styles['unavailability-table']}>
      <Table hasActions>
        <TableHead table={table} />
        <TableBody table={table} data-cy="unavailabilities-table-body" isLoading={isLoading} />
      </Table>

      <TableActions>
        <TablePageSizeSelect pageSize={pageSize} setPageSize={table.setPageSize} totalEntries={data.length} />
        <TablePagination pageCount={table.getPageCount()} gotoPage={table.setPageIndex} />
      </TableActions>
    </div>
  );
};
