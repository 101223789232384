import React from 'react';
import { Colon } from '@GDM/Colon';
import { Text } from '@GDM/Text';
import { CellContext } from '@tanstack/react-table';
import { Event } from '@utils/types/event';
import dayjs from 'dayjs';

export const ReminderCell = ({ row: { original } }: CellContext<Event, unknown>) => {
  if (!original.contract) return <></>;

  const contract = original.contract;
  const endDate = dayjs(contract.end_date, 'YYYY-MM-DD').format('MMMM, DD, YYYY');

  return (
    <div className="d-flex flex-row justify-content-start align-items-center gap-1">
      <Text text="common.contract_end_date" />
      <Colon />
      <div>{endDate}</div>
    </div>
  );
};
