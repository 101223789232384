import React from 'react';
import { Row, Col } from '@GDM/layout';
import useTranslation from '@hooks/useTranslation';
import { getSymbol } from '@utils/currency/getSymbol';
import { useCurrency } from '@utils/string';
import { FormSectionLayout, type ContractFormSectionProps } from '../../components/FormSection/FormSectionLayout';
import { NumericInput } from '../../components/Inputs/Numeric';

export const LandLeasingRent = ({ title, readOnly, form: { control } }: ContractFormSectionProps) => {
  const { t } = useTranslation();
  const currency = useCurrency('code');
  const suffix = `${getSymbol(currency)}/${t('common.year_short')}`;

  return (
    <FormSectionLayout
      title={title}
      body={
        <Row className="p-3">
          <Col md="6">
            <NumericInput
              control={control}
              name="contract_sub_periods_attributes.0.display_price"
              label="sales_management.land_leasing.rent_per_wind_turbine"
              readOnly={readOnly}
              rules={{ required: true }}
              suffix={suffix}
            />
          </Col>
        </Row>
      }
    />
  );
};
