import React, { useCallback, useMemo, useState } from 'react';
import ControlledInput from '@components/FormInputs/ControlledInput';
import ControlledRadioButtons from '@components/FormInputs/ControlledRadioButtons';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { FilterContainer, Filters, useDynamicOptions } from '@GDM/Filters';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  TableState,
  InitialTableState,
} from '@tanstack/react-table';
import { energyOptions } from '@utils/constants/energyOptions';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import type Book from '@utils/types/book';
import { EnergyType, Option } from '@utils/types/common-types';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { useLastSelectedFilter } from 'pages/Settings/Unavailabilities/shared/filters';
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  Path,
  PathValue,
  UseFormReturn,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { Table } from '../Table';
import { TableActions } from '../TableActions';
import { TableBody } from '../TableBody';
import { TableHead } from '../TableHead';
import { TablePageSizeSelect } from '../TablePageSizeSelect';
import { TablePagination } from '../TablePagination';
import { Column, FormType } from './DataTable.types';
import { useColumns } from './useColumns';

export function DataTable<T extends FieldValues>(props: DataTableProps<T>) {
  const columnWithFilters = props.columns.filter((column) => column.filterType);
  const defaultFilters = columnWithFilters.reduce(
    (acc, column) => {
      // If the column is provided a onFilter, it means it's a custom filter and we don't want to set a default value
      if (column.filterType && !column.onFilter) {
        const path = column.id as Path<T>;
        const value = (column.filterDefaultValue ?? '') as DefaultValues<FormType<T>>[Path<T>];

        acc[path] = value;
      }

      return acc;
    },
    { last_selected_filter: null } as DefaultValues<FormType<T>>,
  );
  const filtersForm = useForm<FormType<T>>({ defaultValues: defaultFilters });
  const filters = filtersForm.watch();

  return (
    <div className={props.className}>
      <DataTableContent
        isLoading={props.isLoading}
        filters={filters}
        filtersForm={filtersForm}
        columnWithFilters={columnWithFilters}
        {...props}
      >
        {props.children}
      </DataTableContent>
    </div>
  );
}

function DataTableContent<T extends FieldValues>({
  columns,
  data,
  className,
  isLoading,
  filters,
  filtersForm,
  columnWithFilters,
  children,
  actionBar,
  defaultSort,
  filtersPrefix,
  filtersSuffix,
}: {
  className?: string;
  isLoading?: boolean;
  filters: T;
  filtersForm: UseFormReturn<FormType<T>>;
  columnWithFilters: Column<T>[];
} & DataTableProps<T>) {
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 25 });

  const columnFilters = useMemo(() => {
    return Object.entries(omit(filters, ['last_selected_filter'])).map(([id, value]) => ({ id, value }));
  }, [filters]);

  const columnVisibility = useMemo(() => {
    return columns.reduce((acc, column) => {
      acc[column.id] = column.hide ? false : true;

      return acc;
    }, {} as Record<string, boolean>);
  }, [columns]);

  const tableState = useMemo<Partial<TableState>>(() => {
    return { pagination, columnFilters, columnVisibility };
  }, [pagination, columnFilters, columnVisibility]);

  const initialState = useMemo<Partial<InitialTableState>>(() => {
    if (!defaultSort) {
      return {};
    }

    return { sorting: [{ id: defaultSort.key, desc: defaultSort.desc ?? false }] };
  }, [defaultSort]);

  const internalColumns = useColumns(columns);
  const internalData = useMemo(() => data, [data]);
  /**
   * WARNING: Table declaration must never be at the same level as filtersForm.watch
   * as it will cause a re-render loop, that's why we declare the DataTableContent in DataTable
   */
  const table = useReactTable({
    data: internalData,
    columns: internalColumns,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    state: tableState,
    initialState,
  });

  const filteredData = table.getFilteredRowModel().flatRows.map((row) => row.original);

  useLastSelectedFilter(filtersForm.watch, filtersForm.setValue);

  return (
    <>
      {columnWithFilters.length > 0 && (
        <FormProvider {...filtersForm}>
          <Filters onClear={() => filtersForm.reset()}>
            {filtersPrefix}
            <DataTableFilters columnWithFilters={columnWithFilters} data={data} filteredData={filteredData} />
            {filtersSuffix}
          </Filters>
        </FormProvider>
      )}

      {actionBar && <div className="flex justify-between">{actionBar}</div>}

      {children}

      <Table className={className}>
        <TableHead table={table} />
        <TableBody table={table} data-cy="dispatch-program-table-body" isLoading={isLoading} />
      </Table>

      <TableActions>
        <TablePageSizeSelect
          pageSize={pagination.pageSize}
          setPageSize={table.setPageSize}
          totalEntries={data.length}
        />
        <TablePagination pageCount={table.getPageCount()} gotoPage={table.setPageIndex} />
      </TableActions>
    </>
  );
}

function DataTableFilters<T extends FieldValues>({
  columnWithFilters,
  data,
  filteredData,
}: {
  columnWithFilters: Column<T>[];
  data: T[];
  filteredData: T[];
}) {
  return columnWithFilters.map((column) => (
    <FilterInput key={column.id} column={column} data={data} filteredData={filteredData} />
  ));
}

function FilterInput<T extends FieldValues>({
  column,
  data,
  filteredData,
}: {
  column: Column<T>;
  data: T[];
  filteredData: T[];
}) {
  const { control } = useFormContext<T>();
  const name = column.id as Path<T>;
  const path = column.accessorKey as Path<T>;

  const getOptions = useCallback(
    (data: T[]) => {
      if (column.dataType === 'energy') {
        return energyOptions as Option<PathValue<T, Path<T>>>[];
      }

      const map = new Map<PathValue<T, Path<T>>, Option<PathValue<T, Path<T>>> & { energy: EnergyType }>();

      data.forEach((currentData: T) => {
        const value = get(currentData, path);

        if (column.dataType === 'book' && Array.isArray(value)) {
          value.forEach((v: Book) => {
            const option = getOption(column, v.name as PathValue<T, Path<T>>);

            if (option) map.set(option.key, option.option);
          });

          return;
        }

        const option = getOption(column, value);

        if (option) map.set(option.key, option.option);
      });

      return Array.from(map.values()).sort(sortOptionsByLabelAsc);
    },
    [path, column],
  );

  const options = useDynamicOptions(getOptions, name, filteredData, data);

  const placeholder = column.header;

  if (column.filterType && ['multi-select', 'single-select'].includes(column.filterType)) {
    return (
      <FilterContainer size={column.dataType === 'installation' || column.dataType === 'book' ? 'select' : 'fit'}>
        <ControlledSelect
          options={options}
          name={name}
          control={control}
          isMulti={column.filterType === 'multi-select'}
          placeholder={placeholder}
          inline
          isInstallationOrBook={column.dataType === 'installation' || column.dataType === 'book'}
        />
      </FilterContainer>
    );
  }

  if (column.filterType === 'radio') {
    return (
      <FilterContainer size="fit">
        <ControlledRadioButtons name={name} control={control} options={options} />
      </FilterContainer>
    );
  }

  if (column.filterType === 'search') {
    return (
      <FilterContainer>
        <ControlledInput name={name} control={control} />
      </FilterContainer>
    );
  }
}

type DataTableProps<T extends FieldValues> = {
  columns: Column<T>[];
  data: T[];
  className?: string;
  isLoading?: boolean;
  children?: React.ReactNode;
  /*
   * actionBar is used to display an action bar above the table (download, export, create...etc)
   */
  actionBar?: React.ReactNode;
  defaultSort?: { key: Path<T>; desc?: boolean };
  filtersPrefix?: React.ReactNode;
  filtersSuffix?: React.ReactNode;
};

function getOption<T extends FieldValues>(
  column: Column<T>,
  value: PathValue<T, Path<T>>,
): null | {
  key: PathValue<T, Path<T>>;
  option: Option<PathValue<T, Path<T>>> & { energy: EnergyType };
} {
  if (!value) {
    return null;
  }

  return {
    key: value,
    option: {
      label: column.filterOptionsLabelPrefix
        ? `${column.filterOptionsLabelPrefix}.${value as string}`
        : (value as string),
      value: value,
      energy: column.dataType === 'book' ? 'book' : get(value, 'installation.energy'),
    },
  };
}
