import React, { useState } from 'react';
import { EventsFilters } from '@components/Events/EventsFilters';
import { EventsPageWrapper } from '@components/Events/EventsPageWrapper';
import { FilteredEventsProvider } from '@components/Events/FilteredEventsProvider';
import { useUser } from '@context/User.context';
import { Button } from '@GDM/Button';
import { ALERT_EVENT_TYPES } from '@utils/types/event';

export const Alerts = ({ type }: { type?: 'monitoring' | 'sales' }) => {
  const { permissions } = useUser();
  const [infoModalIsOpen, setInfoModalIsOpen] = useState(false);

  const hasMonitoringPermission = permissions.includes('display:view_monitoring');
  const hasSalesPermission = permissions.includes('display:view_salesfocus');

  return (
    <FilteredEventsProvider
      hasMonitoringPermission={hasMonitoringPermission}
      hasSalesPermission={hasSalesPermission}
      type={type}
      tabType="alerts"
    >
      <div className="d-flex flex-column px-4 py-3">
        <EventsFilters tabType="alerts" />
        <Button
          className="ml-auto"
          onClick={() => setInfoModalIsOpen(true)}
          size="sm"
          variant="primary-2"
          icon="HelpCircle"
          data-cy="events-email-settings"
        />
      </div>
      <EventsPageWrapper
        tabType="alerts"
        isPortfolio
        eventableId={null}
        eventableType={null}
        eventTypes={ALERT_EVENT_TYPES}
        infoModalIsOpen={infoModalIsOpen}
        setInfoModalIsOpen={setInfoModalIsOpen}
      />
    </FilteredEventsProvider>
  );
};
