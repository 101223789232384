import React from 'react';
import { MeterNameCell } from '@components/ui/table/cell/MeterNameCell';
import { Text } from '@GDM/Text';
import { ColumnDef, Row } from '@tanstack/react-table';
import { Event, EventTabType } from '@utils/types/event';
import { CommentCell } from './CommentCell';
import { ContractCell } from './ContractCell';
import { ReminderActionCell } from './ReminderActionCell';
import { ReminderCell } from './ReminderCell';

type Props = {
  tabType: EventTabType;
  isPortfolio?: boolean;
};

const getColumns = ({ isPortfolio, tabType }: Props): ColumnDef<Event>[] => {
  const METER: ColumnDef<Event> = {
    header: 'common.installation',
    accessorKey: 'meter_name',
    cell: ({ row: { original } }: { row: Row<Event> }) => {
      const installation = {
        name: original.installation.name,
        energy: original.installation.energy,
        average_q50: null,
        latitude: null,
        longitude: null,
        nb_simulations: null,
        owner_company_number: null,
        has_meter: null,
        max_value: null,
      };

      return (
        <MeterNameCell
          hasMeter={installation.has_meter}
          installation_name={installation.name || ''}
          installation_energy={installation.energy}
          muted={original.finished}
        />
      );
    },
  };

  const OPEN: ColumnDef<Event> = {
    header: 'common.open',
    accessorKey: 'finished',
    cell: ({ row }) => {
      if (!row.original.finished) {
        return <Text text="common.yes" />;
      } else {
        return <Text text="common.no" />;
      }
    },
  };

  const CONTRACT: ColumnDef<Event> = {
    header: 'common.contract',
    accessorKey: 'contract',
    cell: ContractCell,
  };

  const REMINDER: ColumnDef<Event> = {
    header: 'admin.alerts.reminder',
    accessorKey: 'reminder',
    cell: ReminderCell,
  };

  const EVENT_TYPE: ColumnDef<Event> = {
    header: 'common.type',
    id: 'event_type',
    accessorKey: 'type',
    cell: CommentCell,
  };

  const CREATED_AT: ColumnDef<Event> = {
    header: 'common.created_at',
    accessorKey: 'start_date',
  };

  const ENDED_AT: ColumnDef<Event> = {
    header: 'monitoring.alerts.end',
    accessorKey: 'end_date',
  };

  const REMINDER_ACTION: ColumnDef<Event> = {
    header: 'common.status.title',
    enableSorting: false,
    cell: ReminderActionCell,
  };

  const columns: ColumnDef<Event>[] = [];
  if (isPortfolio || tabType === 'reminders') columns.push(METER);
  if (tabType === 'reminders') columns.push(CONTRACT);
  columns.push(EVENT_TYPE);
  if (tabType === 'reminders') columns.push(REMINDER);
  if (tabType === 'alerts') {
    columns.push(CREATED_AT);
    columns.push(ENDED_AT);
  }
  if (tabType === 'reminders') columns.push(REMINDER_ACTION);
  if (tabType === 'alerts') columns.push(OPEN);

  return columns;
};

export default getColumns;
