import React from 'react';
import ControlledDatePicker from '@components/FormInputs/ControlledDatePicker';
import ControlledRadioButtons from '@components/FormInputs/ControlledRadioButtons';
import { ControlledToggle } from '@components/FormInputs/ControlledToggle';
import { Restricted } from '@components/Restricted';
import { FilterContainer, Filters } from '@GDM/Filters';
import type { DataPeriod } from '@utils/types/dataPeriod';
import type { TimeSeries } from '@utils/types/timeSeries';
import dayjs from 'dayjs';
import type { UseFormReturn } from 'react-hook-form';
import { MarketPriceCurveSelect } from '../charts/MarketPriceCurveSelect';
import type { ProductionType } from '../hooks';
import type { ProductionFilters as ProductionFiltersType } from '../Production';

export const ProductionFilters = ({
  dataPeriod,
  type,
  enableReactivePowerTable,
  filtersForm,
  showMarketPriceCurve,
  marketPrices,
}: {
  dataPeriod: DataPeriod;
  type: ProductionType;
  enableReactivePowerTable: boolean;
  filtersForm: UseFormReturn<ProductionFiltersType>;
  showMarketPriceCurve: boolean;
  marketPrices?: Record<string, TimeSeries>;
}) => {
  const isMarketPlayer = type === 'market_player';
  const isDatetime = !['monthly', 'daily'].includes(dataPeriod);

  return (
    <Filters className="p-0 flex-grow-1">
      <FilterContainer size={isDatetime ? 'datetimepicker-range' : 'datepicker'}>
        <ControlledDatePicker
          control={filtersForm.control}
          name="dates"
          picks={isDatetime ? 'datetime' : 'months'}
          maxDate={dataPeriod === 'monthly' ? null : dayjs().startOf('day').add(14, 'day').toDate()}
          selectsRange
          hasPreDefinedPeriods
          transformDate={(dates) => {
            if (!Array.isArray(dates)) {
              throw new Error('Invalid date range');
            }

            const [startDate, endDate] = dates;

            if (!startDate || !endDate) return dates;

            // The backend limits the length of the date range we can query
            // Maximums are set a bit below the maximum possible value to ensure
            // that every request send from the front is valid and not a limit case
            const maximumQueryPeriods = {
              minutes: [9, 'week'], // 9 weeks in the back
              daily: [2.9, 'month'], // 3 months in the back
              monthly: [23, 'month'], // 2 years in the back
            } as const;

            const maxQueryPeriod = dataPeriod && maximumQueryPeriods[dataPeriod];

            const inclusiveEndDate = endDate
              ? dayjs(endDate)
                  .endOf(dataPeriod === 'monthly' ? 'month' : 'day')
                  .toDate()
              : null;

            const definitiveStartDate =
              maxQueryPeriod && startDate && inclusiveEndDate
                ? new Date(
                    Math.max(
                      startDate.getTime(),
                      dayjs(inclusiveEndDate).subtract(maxQueryPeriod[0], maxQueryPeriod[1]).toDate().getTime(),
                    ),
                  )
                : startDate;

            return [definitiveStartDate, inclusiveEndDate];
          }}
        />
      </FilterContainer>

      {!isMarketPlayer && (
        <FilterContainer size="fit">
          <ControlledRadioButtons
            control={filtersForm.control}
            name="dataPeriod"
            options={[
              { label: 'common.units.short.minutes', value: 'minutes' },
              { label: 'common.daily', value: 'daily' },
              { label: 'common.monthly', value: 'monthly' },
            ]}
          />
        </FilterContainer>
      )}

      {showMarketPriceCurve && (
        <FilterContainer size="fit">
          <MarketPriceCurveSelect marketPrices={marketPrices} filtersForm={filtersForm} />
        </FilterContainer>
      )}

      {!isMarketPlayer && (
        <FilterContainer size="fit">
          <Restricted permissions={['display:view_monitoring_dashboard_meter_reactive']}>
            <ControlledToggle
              control={filtersForm.control}
              name="showReactivePowerMonitoring"
              disabled={!enableReactivePowerTable}
              label="monitoring.production.show_reactive_table"
              tooltip="common.reactifinfo"
            />
          </Restricted>
        </FilterContainer>
      )}

      {!isMarketPlayer && (
        <FilterContainer size="fit">
          <Restricted permissions={['display:view_monitoring_dashboard_meter_performances']}>
            <ControlledToggle
              control={filtersForm.control}
              name="showProductionMinMaxAverage"
              label="monitoring.production.show_statistics"
            />
          </Restricted>
        </FilterContainer>
      )}
    </Filters>
  );
};
