import React from 'react';
import type { FormFilterType } from '@GDM/Filters/filters.types';
import type { CountryCode } from '@utils/types/countries';
import type { SharingRight } from '@utils/types/unavailability';
import { useForm } from 'react-hook-form';
import { SharingRightTable } from './SharingRightTable';

export const SharingRights = () => {
  const filtersForm = useForm<SharingRightsFiltersForm>({
    defaultValues: {
      installation: [],
      country: [],
      book: [],
      aggregator: [],
    },
  });
  const filters = filtersForm.watch();

  return <SharingRightTable filters={filters} filtersForm={filtersForm} />;
};

export type SharingRightsFiltersForm = FormFilterType<{
  installation: string[];
  country: CountryCode[];
  book: string[];
  aggregator: string[];
  formatted_sharing_status: SharingRight['sharing_status'] | null;
}>;
