import React from 'react';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { FilterContainer, Filters, useDynamicOptions } from '@GDM/Filters';
import type { DispatchProgramSharingRight, SharingRight } from '@utils/types/unavailability';
import { type UseFormReturn } from 'react-hook-form';
import {
  getAggregatorOptions,
  getBookOptions,
  getCountryOptions,
  getInstallationOptions,
  useLastSelectedFilter,
} from '../shared/filters';
import type { SharingRightsFiltersForm } from './SharingRights';

export const SharingRightFilters = ({
  form,
  allSharingRights,
  filteredSharingsRights,
}: {
  form: UseFormReturn<SharingRightsFiltersForm>;
  allSharingRights: SharingRight[] | DispatchProgramSharingRight[];
  filteredSharingsRights: SharingRight[] | DispatchProgramSharingRight[];
}) => {
  const { control, watch, setValue } = form;

  const installationOptions = useDynamicOptions(
    getInstallationOptions,
    'installation',
    filteredSharingsRights,
    allSharingRights,
  );
  const countryOptions = useDynamicOptions(getCountryOptions, 'country', filteredSharingsRights, allSharingRights);
  const bookOptions = useDynamicOptions(getBookOptions, 'book', filteredSharingsRights, allSharingRights);
  const aggregatorOptions = useDynamicOptions(
    getAggregatorOptions,
    'aggregator',
    filteredSharingsRights,
    allSharingRights,
  );

  useLastSelectedFilter(watch, setValue);

  return (
    <Filters className="p-0">
      <FilterContainer size="select">
        <ControlledSelect
          inline
          control={control}
          name="installation"
          options={installationOptions}
          isInstallationOrBook
          placeholder="common.installation"
          isMulti
          isClearable
        />
      </FilterContainer>
      <FilterContainer size="select">
        <ControlledSelect
          inline
          control={control}
          name="country"
          options={countryOptions}
          placeholder="common.country"
          isCountry
          isMulti
          isClearable
        />
      </FilterContainer>
      <FilterContainer size="select">
        <ControlledSelect
          inline
          control={control}
          name="book"
          options={bookOptions}
          placeholder="common.book"
          isClearable
          isMulti
          isInstallationOrBook
        />
      </FilterContainer>
      <FilterContainer size="select">
        <ControlledSelect
          inline
          control={control}
          name="aggregator"
          options={aggregatorOptions}
          placeholder="monitoring.unavailabilities.aggregator"
          isMulti
          isClearable
        />
      </FilterContainer>
      <FilterContainer size="select">
        <ControlledSelect
          inline
          control={control}
          name="formatted_sharing_status"
          options={[
            { label: 'monitoring.unavailabilities.shared', value: 'shared' },
            { label: 'monitoring.unavailabilities.unshared', value: 'unshared' },
            { label: 'monitoring.unavailabilities.not_available', value: 'not_available' },
          ]}
          placeholder="monitoring.unavailabilities.sharing_permission"
          isClearable
        />
      </FilterContainer>
    </Filters>
  );
};
