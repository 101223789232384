import React from 'react';
import useTranslation from '@hooks/useTranslation';
import styles from './contract-number-cell.module.scss';

export const ContractNumberCell = ({ contractNumber }: { contractNumber: string }) => {
  return (
    <div className={styles['contract-number-cell']}>
      <span>N°</span> : {contractNumber}
    </div>
  );
};

export const ParcelNameCell = ({ parcelName }: { parcelName: string }) => {
  const { t } = useTranslation();
  const label = 'sales_management.land_leasing.parcel_name';

  return (
    <div className={styles['contract-number-cell']}>
      <span>{t(label)}</span> : {parcelName}
    </div>
  );
};
