import React, { useEffect, useState } from 'react';
import { getFormData } from '@api/mandates';
import { Button } from '@GDM/Button';
import { Col, Row } from '@GDM/layout';
import { Text } from '@GDM/Text';
import { useGrids } from '@hooks/requests/useGrids';
import useAxiosDeprecated from '@hooks/useAxios';
import { useFormLegacy } from '@hooks/useFormLegacy';
import useTranslation from '@hooks/useTranslation';
import { isEnedisLike } from '@pages/Installations/isEnedisLike';
import { formatUpsertBody } from '@pages/Installations/Mandate/utils/formatMandateBody';
import { COUNTRIES } from '@utils/constants/countries';
import { CountryCode } from '@utils/types/countries';
import { MandateFormType, Mandate } from '@utils/types/mandate';
import { DownloadOriginalButton } from './buttons/DownloadOrginialButton';
import { DownloadTemplateButton } from './buttons/DownloadTemplateButton';
import SaveMandateBtn from './buttons/SaveMandateBtn';
import { CompanyNumberInput } from './fields/CompanyNumberInput';
import { CountrySelect } from './fields/CountrySelect';
import { GridSelect } from './fields/GridSelect';
import { SignatoryEmailInput } from './fields/SignatoryEmailInput';
import { SignatoryNameInput } from './fields/SignatoryNameInput';
import { SignInput } from './fields/SignInput';
import { UploadMandateInput } from './fields/UploadMandateInput';
import RenderStatus from './text/RenderStatus';
import RequestStatusMessage from './text/RequestStatusMessage';

export type FormMandatesProps = {
  selectedMandate: Mandate | null;
  onSuccess: (mandate?: Mandate) => void;
  onClose?: () => void;
};

/**
 * Component to edit or create a mandate
 */
export const FormMandates = ({ selectedMandate, onSuccess, onClose }: FormMandatesProps) => {
  const defaultValues: MandateFormType = {
    country: 'FR',
    grid_id: null,
    signatory_email: '',
    signatory_name: '',
    mandate_file: null,
    sirets: '',
    status: null,
    sign_request_type: 'online',
  };

  const [form, setForm, setFormData] = useFormLegacy(defaultValues);

  const [requestMandate, upsertMandate] = useAxiosDeprecated({
    url: selectedMandate?.uuid ? `/mandates/${selectedMandate.uuid}` : '/mandates',
    method: selectedMandate?.uuid ? 'PUT' : 'POST',
  });

  const grids = useGrids(form.country);
  const grid = (grids.data || []).find((grid) => grid.id === form.grid_id);

  const [valuesHasChanged, setValuesHasChanged] = useState(false);
  const toRenew = form.status === 'to_renew' || form.status === 'expired' || form.status === 'signed';
  const requiredFields =
    !form.sirets || !form.signatory_name || !form.signatory_email || requestMandate.loading === 'LOADING';

  const allowOnlineSignature = !!grid && isEnedisLike(form.country, grid.name.toLowerCase());
  const allowUploadMandate = form.sign_request_type === 'offline';
  const mandateHasFile = !!selectedMandate && !!selectedMandate.url;
  const allowDownloadTemplate =
    !mandateHasFile && allowUploadMandate && !!grid && isEnedisLike(form.country, grid.name.toLowerCase());
  const allowDownloadOriginal = !!selectedMandate;

  const { t } = useTranslation();

  /**
   * EFFECTS
   */

  useEffect(() => {
    if (selectedMandate)
      setForm((previous) => ({
        ...previous,
        ...selectedMandate,
        country: selectedMandate.country || 'FR',
        sirets: selectedMandate.sirets.join(', '),
      }));
    else setForm(defaultValues);
  }, [selectedMandate]);

  useEffect(() => {
    if (requestMandate.loading === 'LOADED' && requestMandate.data) {
      setForm({ ...form, status: requestMandate.data.status });

      onSuccess?.(requestMandate.data);
    }
  }, [requestMandate]);

  /**
   * ACTIONS
   */
  const handleChange =
    (key: keyof MandateFormType) =>
    (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      setFormData(key, event.target.value);
      setValuesHasChanged(true);
    };

  const handleSave = () => upsertMandate({ data: getFormData(formatUpsertBody(form)) });

  return (
    <div>
      {form.status ? (
        <Row>
          <Col md={12} className="tt:uppercase">
            <RenderStatus status={form.status} />
          </Col>
        </Row>
      ) : null}

      <Row>
        <Col md={6} className="my-1">
          <CountrySelect
            countries={Object.keys(COUNTRIES) as CountryCode[]}
            value={form.country}
            onChange={(country: CountryCode | null) => {
              setFormData('country', country);
              setValuesHasChanged(true);
            }}
          />
        </Col>
        <Col md={6} className="my-1">
          <GridSelect
            grids={grids.data || []}
            value={form.grid_id}
            onChange={(grid_id: number | null) => {
              setFormData('grid_id', grid_id);
              setValuesHasChanged(true);
            }}
            isLoading={grids.isPending}
          />
        </Col>
        <Col md={6} className="my-1">
          <SignatoryNameInput value={form.signatory_name} onChange={handleChange('signatory_name')} />
        </Col>
        <Col md={6} className="my-1">
          <SignatoryEmailInput value={form.signatory_email} onChange={handleChange('signatory_email')} />
        </Col>
        <Col md={12} className="my-1">
          <CompanyNumberInput
            value={form.sirets}
            country={form.country}
            onChange={handleChange('sirets')}
            disabled={toRenew || false}
          />
        </Col>
        <Col md={12} className="my-1">
          <SignInput
            allowOnlineSignature={allowOnlineSignature}
            value={form.sign_request_type || ''}
            onChange={(value) => setFormData('sign_request_type', value ?? null)}
          />
        </Col>

        {allowUploadMandate && (
          <>
            <Col md={12} className="my-1">
              <UploadMandateInput
                file={form.mandate_file?.[0] || null}
                setFormData={setFormData}
                setValuesHasChanged={setValuesHasChanged}
              />
            </Col>
            {!allowDownloadTemplate && (
              <Col md={12} className="my-1">
                <Text text="admin.installations.ops_will_send_template" multiline />
              </Col>
            )}
          </>
        )}
      </Row>

      <Row>
        <Col className="d-flex flex-row mb-3 justify-content-end align-items-center" md={12}>
          {(!form.sirets || !form.signatory_name || !form.signatory_email) && (
            <div style={{ flex: 1 }}>
              <Text size="sm" text="admin.installations.all_fields_required" type="danger" />
            </div>
          )}

          {requestMandate.error?.message && (
            <div style={{ flex: 1, overflow: 'hidden' }} className="mr-2">
              <Text size="sm" text={requestMandate.error?.message} type="danger" />
            </div>
          )}

          <div className="d-flex">
            {allowDownloadTemplate ? <DownloadTemplateButton form={form} /> : null}
            {allowDownloadOriginal ? <DownloadOriginalButton selectedMandate={selectedMandate} /> : null}
            {onClose && <Button variant="link" size="sm" text="common.cancel" onClick={onClose} className="mr-2" />}
            <SaveMandateBtn
              onSave={handleSave}
              loading={requestMandate.loading}
              toRenew={toRenew}
              requiredFields={requiredFields}
              valuesHasChanged={valuesHasChanged}
            />
          </div>
        </Col>

        <Col md={12}>
          <RequestStatusMessage status={requestMandate.loading}>
            {t('admin.installations.correctly_saved')}
          </RequestStatusMessage>
          {form.sign_request_type === 'online' ? (
            <RequestStatusMessage status={requestMandate.loading}>
              {t('admin.installations.will_receive_mail')}
            </RequestStatusMessage>
          ) : null}
        </Col>

        {/* <Col md={12}>
          <RequestStatusMessage status={requestUpload.loading}>
            {t('admin.installations.correctly_inserted')}
          </RequestStatusMessage>
        </Col> */}
      </Row>
    </div>
  );
};
