import React from 'react';
import useTranslation from '@hooks/useTranslation';
import type { CellContext } from '@tanstack/react-table';
import { NonInjectedEnergy } from '@utils/types/nonInjectedEnergy';
import type { Unavailability } from '@utils/types/unavailability';

export function SourceCell<T extends NonInjectedEnergy>(
  props: React.PropsWithChildren<CellContext<T, unknown>>,
): string;
export function SourceCell<T extends Unavailability>(props: React.PropsWithChildren<CellContext<T, unknown>>): string;
export function SourceCell<T extends Unavailability | NonInjectedEnergy>(
  props: React.PropsWithChildren<CellContext<T, unknown>>,
): string {
  const { t } = useTranslation();

  return t(`monitoring.unavailabilities.sources.${props.row.original.source}`);
}
