import React, { useMemo } from 'react';
import { AccessorFn, ColumnDef, FilterFnOption } from '@tanstack/react-table';
import { FieldValues } from 'react-hook-form';
import { HeadCell } from '../Cells';
import { arrayFilter, arrayFilterMulti, arrayFilterMultiBook, toggleFilterWithAll } from '../utils/filterFns';
import { arrayFilterBook } from '../utils/filterFns';
import { Column, FilterType } from './DataTable.types';
import { getCell } from './getCell';

export function useColumns<T extends FieldValues>(columns: Column<T>[]): ColumnDef<T>[] {
  return useMemo(
    () =>
      columns.map((column) => {
        const cellComponent = getCell(column);
        const internalColumn: ColumnDef<T> = {
          id: column.id,
          accessorKey: column.accessorKey,
          accessorFn: (column.accessorFn as AccessorFn<T, unknown>) ?? undefined,
          cell: cellComponent,
          header: () => <HeadCell label={column.header ?? ''} tooltip={column.tooltip} />,
          filterFn: getFilterFn<T>(column) as FilterFnOption<T>,
        };

        return internalColumn;
      }),
    [columns],
  );
}

function getFilterFn<T extends FieldValues>(column: Column<T>) {
  if (!column.filterType) {
    return undefined;
  }

  const filterFnMap: { [key in FilterType]: FilterFnOption<FieldValues> } = {
    'multi-select': column.dataType === 'book' ? arrayFilterMultiBook : arrayFilterMulti,
    'single-select': column.dataType === 'book' ? arrayFilterBook : arrayFilter,
    radio: toggleFilterWithAll,
    search: 'includesString',
    toggle: 'equals',
    date: 'equals',
  };

  return filterFnMap[column.filterType];
}
