import { getSymbol } from '@utils/currency/getSymbol';
import { currencies } from '@utils/types/currency';
import { AcceptableUnits } from '../value-with-unit';

export const specialUnitsMap: Map<AcceptableUnits, { default: string; K?: string; M?: string; B?: string }> = new Map([
  ['V', { default: 'V', K: 'kV', M: 'MV', B: 'GV' }],
  ['kWh', { default: 'kWh', K: 'MWh', M: 'GWh', B: 'TWh' }],
  ['kW', { default: 'kW', K: 'MW', M: 'GW', B: 'TW' }],
  ['kVAR', { default: 'kVAR', K: 'MVAR', M: 'GVAR', B: 'TVAR' }],
  ['kVARh', { default: 'kVARh', K: 'MVARh', M: 'GVARh', B: 'TVARh' }],
  ['degree', { default: '°' }],
  ['percentage', { default: '%' }],
  ['EUR/Meter', { default: '€/Meter' }],
  ['EUR/Turbine', { default: '€/Turbine' }],
]);

currencies.forEach((currency) => {
  specialUnitsMap.set(currency, { default: getSymbol(currency) });
  specialUnitsMap.set(`${currency}/MWh`, { default: `${getSymbol(currency)}/MWh` });
  specialUnitsMap.set(`${currency}/MW`, { default: `${getSymbol(currency)}/MW` });
  specialUnitsMap.set(`${currency}/Turbine`, { default: `${getSymbol(currency)}/Turbine` });
});
