import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import 'dayjs/locale/de';
import 'dayjs/locale/es';
import 'dayjs/locale/it';
import 'dayjs/locale/pt';
import 'dayjs/locale/pl';

// Only add supported locales that have been imported above
const SUPPORTED_LOCALES = ['fr', 'en', 'de', 'es', 'it'];

const localStorageLocale = localStorage.getItem('locale');

dayjs.locale(
  SUPPORTED_LOCALES.includes(localStorageLocale || navigator.language)
    ? localStorageLocale || navigator.language
    : 'en',
);

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);

declare let window: Window & { moment: typeof dayjs };

window.moment = dayjs;
